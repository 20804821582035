export const parseSearchStringAndGetCustomFilters = (
  searchString: string,
  customFilterParameterKey: string
): { customFilter: string; text: string } => {
  const regex = /\[(\w+)=([^\]]+)\]/gu;
  let match;
  const result: { [key: string]: string } = {};

  while ((match = regex.exec(searchString)) !== null) {
    result[match[1]] = match[2];
  }

  const otherText = searchString.replaceAll(regex, '').trim();

  if (result[customFilterParameterKey]) {
    return { customFilter: result[customFilterParameterKey], text: otherText };
  } else {
    return { customFilter: '', text: otherText };
  }
};
