import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FieldController, SelectField } from '@module/mdrt-org/shared/components';
import { CountryId } from '@module/mdrt-org/shared/utils/enums';
import { FormInput, type SelectOption } from '@shared/ui-components';

import { useAccountSettingsPageContext } from '../../providers/account-settings-page-context';
import {
  MAX_ADDRESS_LINE_LENGTH,
  MAX_CITY_LENGTH,
  MAX_ZIP_CODE_LENGTH,
} from '../../utils/constants/account-settings';
import {
  type ContactInformationFormData,
  type ContactInformationPhoneNumberFormDataPartial,
} from '../../utils/types/form-data';

import { type AccountSettingsContactInformationContent } from './get-account-settings-contact-information-content';
import styles from './contact-information-form.module.scss';

const PARENT_CLASSNAME = 'contact-information-form';

export const LocalAddressSection = ({
  cmsContent,
  countryOptions,
  customerProfileCountryId,
}: {
  cmsContent: AccountSettingsContactInformationContent;
  countryOptions: SelectOption[];
  customerProfileCountryId: Nullable<number>;
}) => {
  const { countriesData, localAddressStateOptions } = useAccountSettingsPageContext();

  const formMethods = useFormContext();
  const { setValue, watch } = formMethods;

  const watchLocalAddressCountry = watch('localAddressCountry');
  const watchLocalAddressLineOne = watch('localAddressLineOne');
  const watchLocalAddressLineTwo = watch('localAddressLineTwo');
  const watchLocalAddressLineThree = watch('localAddressLineThree');
  const watchLocalAddressCityOrTown = watch('localAddressCityOrTown');
  const watchLocalAddressStateOrProvinceId = watch('localAddressStateOrProvinceId');

  const selectedLocalAddressCountryId = watchLocalAddressCountry?.value as number;
  const selectedLocalAddressCountry = countriesData.find(
    (country) => country.countryId === selectedLocalAddressCountryId
  );

  const isLocalAddressFieldFilled = Boolean(
    watchLocalAddressLineOne ||
      watchLocalAddressLineTwo ||
      watchLocalAddressLineThree ||
      watchLocalAddressCityOrTown ||
      watchLocalAddressStateOrProvinceId
  );

  const sharedLocalAddressFieldProps = {
    isRequired:
      isLocalAddressFieldFilled ||
      customerProfileCountryId === CountryId.Japan ||
      customerProfileCountryId === CountryId.China,
    rules: {
      validate: {
        required: (
          value:
            | string
            | number
            | boolean
            | ContactInformationPhoneNumberFormDataPartial
            | SelectOption
            | ContactInformationPhoneNumberFormDataPartial[]
            | undefined
        ) => {
          if (
            !value &&
            (isLocalAddressFieldFilled ||
              customerProfileCountryId === CountryId.Japan ||
              customerProfileCountryId === CountryId.China)
          ) {
            return cmsContent.requiredLabel;
          }

          return true;
        },
      },
    },
  };

  useEffect(() => {
    setValue('localAddressAdded', isLocalAddressFieldFilled);
  }, [isLocalAddressFieldFilled, setValue]);

  return (
    <>
      <h2 className={styles[`${PARENT_CLASSNAME}__heading`]}>
        {cmsContent.localLanguageAddressTitleLabel}
      </h2>
      <SelectField
        isClearable
        isDisabled
        isSearchable
        label={cmsContent.countryLabel}
        name="localAddressCountry"
        options={countryOptions}
      />
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine1Label}
        name="localAddressLineOne"
        {...sharedLocalAddressFieldProps}
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine2Label}
        name="localAddressLineTwo"
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine3Label}
        name="localAddressLineThree"
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.cityLabel}
        name="localAddressCityOrTown"
        {...sharedLocalAddressFieldProps}
      >
        <FormInput maxLength={MAX_CITY_LENGTH} />
      </FieldController>
      <SelectField
        isClearable
        isRequired={
          (isLocalAddressFieldFilled && selectedLocalAddressCountry?.stateProvinceIsRequired) ||
          customerProfileCountryId === CountryId.Japan ||
          customerProfileCountryId === CountryId.China
        }
        isSearchable
        label={cmsContent.stateProvinceLabel}
        name="localAddressStateOrProvinceId"
        options={localAddressStateOptions}
        rules={{
          validate: {
            required: (value) =>
              (!value &&
                isLocalAddressFieldFilled &&
                selectedLocalAddressCountry?.stateProvinceIsRequired) ||
              (!value &&
                (customerProfileCountryId === CountryId.Japan ||
                  customerProfileCountryId === CountryId.China))
                ? cmsContent.requiredLabel
                : true,
          },
        }}
      />
      <FieldController<ContactInformationFormData>
        isRequired={
          (isLocalAddressFieldFilled && selectedLocalAddressCountry?.postalCodeIsRequired) ||
          selectedLocalAddressCountry?.countryId === CountryId.Japan ||
          selectedLocalAddressCountry?.countryId === CountryId.China
        }
        label={cmsContent.postalCodeLabel}
        name="localAddressZipCode"
        rules={{
          validate: {
            required: (value) =>
              !value &&
              (selectedLocalAddressCountry?.countryId === CountryId.Japan ||
                selectedLocalAddressCountry?.countryId === CountryId.China ||
                (isLocalAddressFieldFilled && selectedLocalAddressCountry?.postalCodeIsRequired))
                ? cmsContent.requiredLabel
                : true,
          },
        }}
      >
        <FormInput maxLength={MAX_ZIP_CODE_LENGTH} />
      </FieldController>
    </>
  );
};
