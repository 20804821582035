'use client';

import { Chip } from '@module/mdrt-org/shared/components';
import { SEARCH_CONTENT_TYPE_PARAM_KEY } from '@module/mdrt-org/shared/utils/constants';
import { ContentType } from '@module/mdrt-org/shared/utils/data/constants';
import { ChipTheme } from '@shared/ui-components';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';
import { type SearchPageSearchParameters } from '../../types';

import styles from './search-type-filter.module.scss';

export type SearchTypeFilterProps = {
  contentType: number | null;
  onContentTypeChange: (searchParameters: SearchPageSearchParameters) => void;
  searchPageCmsContent: SearchCmsContent['searchPage'];
};

export const SearchTypeFilter = ({
  contentType,
  onContentTypeChange,
  searchPageCmsContent,
}: SearchTypeFilterProps) => {
  const contentTypeEntries = [
    { label: searchPageCmsContent?.AllResourcesLabel || 'All resources', value: null },
    { label: searchPageCmsContent?.ArticlesLabel || 'Articles', value: ContentType.Article },
    { label: searchPageCmsContent?.VideosLabel || 'Videos', value: ContentType.Video },
    { label: searchPageCmsContent?.AudioLabel || 'Audio', value: ContentType.Audio },
    { label: searchPageCmsContent?.GuidesLabel || 'Guides', value: ContentType.Guide },
  ];

  const handleContentTypeChange = (nextContentType: number | null) => {
    onContentTypeChange({ [SEARCH_CONTENT_TYPE_PARAM_KEY]: nextContentType?.toString() ?? '' });
  };

  const filterItems = contentTypeEntries.map((contentTypeEntry) => {
    const isActiveFilter = contentType === contentTypeEntry.value;
    return (
      <li key={contentTypeEntry.value}>
        <Chip
          aria-current={isActiveFilter}
          aria-label={contentTypeEntry.label}
          label={contentTypeEntry.label}
          onClick={
            contentType === contentTypeEntry.value
              ? undefined
              : () => handleContentTypeChange(contentTypeEntry.value)
          }
          theme={isActiveFilter ? ChipTheme.Active : ChipTheme.Light}
        />
      </li>
    );
  });

  return <ul className={styles['search-type-filter']}>{filterItems}</ul>;
};
