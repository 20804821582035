'use client';

import classNames from 'classnames';
import { type ButtonHTMLAttributes, forwardRef, type MouseEvent } from 'react';
import { useFocusVisible } from 'react-aria';

import { ScoreGauge } from '@shared/ui-components';
import { useMounted } from '@shared/utils';

import styles from './quiz-tab-item.module.scss';

type QuizTabItemProps = {
  assessmentScore: number;
  buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
  isActive: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  scoreLabel: string | null | undefined;
};

export const QuizTabItem = forwardRef<HTMLButtonElement, QuizTabItemProps>(
  ({ assessmentScore, buttonProps, isActive, onClick, scoreLabel }, ref) => {
    const isMounted = useMounted();
    const { isFocusVisible } = useFocusVisible();

    return (
      <button
        aria-label={scoreLabel ?? undefined}
        className={classNames(styles['quiz-tab-item'], {
          [styles['quiz-tab-item--focus-visible']]: isMounted && isFocusVisible,
        })}
        ref={ref}
        type="button"
        {...buttonProps}
        onClick={(event) => {
          buttonProps.onClick?.(event);
          onClick?.(event);
        }}
      >
        <ScoreGauge
          isActive={isActive}
          isSecondary
          score={assessmentScore}
          scoreLabel={scoreLabel}
        />
      </button>
    );
  }
);
