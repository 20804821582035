'use client';

import { useForm } from 'react-hook-form';

import { useUserContext, FieldController, SelectField } from '@module/mdrt-org/shared/components';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { transformYearOfBirthDefaultValue } from '@module/mdrt-org/shared/utils/helpers';
import { useConfirmNavigation, useMutationHandler } from '@module/mdrt-org/shared/utils/hooks';
import { LATIN_ONLY_NAME_REGEXP } from '@module/mdrt-org/shared/utils/types';
import { Button, FormInput, FormWrapper, type SelectOption } from '@shared/ui-components';
import { ButtonType, formBaseConfig, type ServerActionResponse } from '@shared/utils';

import { useAccountSettingsPageContext } from '../../providers';
import {
  MAX_AGE,
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_MIDDLE_NAME_LENGTH,
  MAX_PREFERRED_NAME_LENGTH,
  MIN_AGE,
} from '../../utils/constants/account-settings';
import { allowOnlyNumbersTransform, useAddAccountSettingsErrorToast } from '../../utils/helpers';
import { type PersonalInfoData, type PersonalInfoFormData } from '../../utils/types/form-data';

import { type AccountSettingsPersonalInfoContent } from './get-account-settings-personal-info-content';
import styles from './personal-info-form.module.scss';

type PersonalInfoFormProps = {
  cmsContent: AccountSettingsPersonalInfoContent;
  gendersOptions: SelectOption[];
  prefixesOptions: SelectOption[];
  suffixesOptions: SelectOption[];
  tabUrl: string;
};

export const PersonalInfoForm = ({
  cmsContent,
  gendersOptions,
  prefixesOptions,
  suffixesOptions,
  tabUrl,
}: PersonalInfoFormProps) => {
  const {
    customerProfileContextData,
    resetCustomerProfileContextData,
    setCustomerProfileContextData,
  } = useAccountSettingsPageContext();
  const { personId } = useUserContext();
  const addErrorToast = useAddAccountSettingsErrorToast();

  const currentYear = new Date().getFullYear();
  const minYearOfBirth = currentYear - MAX_AGE;
  const maxYearOfBirth = currentYear - MIN_AGE;

  const formMethods = useForm<PersonalInfoFormData>({
    ...formBaseConfig,
    defaultValues: {
      firstName: customerProfileContextData.firstName ?? '',
      gender:
        customerProfileContextData?.gender || customerProfileContextData?.gender === 0
          ? gendersOptions.find(
              (gender) => gender?.value === `${customerProfileContextData.gender}`
            )
          : null,
      lastName: customerProfileContextData.lastName ?? '',
      middleName: customerProfileContextData.middleName ?? '',
      preferredName: customerProfileContextData.nickname ?? '',
      prefix: customerProfileContextData.prefix
        ? {
            label: customerProfileContextData.prefix,
            value: customerProfileContextData.prefix,
          }
        : null,
      suffix: customerProfileContextData.suffix
        ? {
            label: customerProfileContextData.suffix,
            value: customerProfileContextData.suffix,
          }
        : null,
      yearOfBirth: transformYearOfBirthDefaultValue(customerProfileContextData?.birthday),
    },
  });

  const {
    formState: { isDirty },
    reset,
  } = formMethods;

  const onFailure = (result: ServerActionResponse) => {
    if (result.status !== 200 && 'message' in result) {
      resetCustomerProfileContextData();
      addErrorToast();
    }
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<PersonalInfoData>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const handleSubmit = async (data: PersonalInfoFormData) => {
    const formData = {
      firstName: data.firstName,
      gender: Number(data.gender?.value),
      lastName: data.lastName,
      middleName: data.middleName,
      preferredName: data.preferredName,
      prefix: data.prefix && data.prefix.value.toString(),
      suffix: data.suffix && data.suffix.value.toString(),
      yearOfBirth: data.yearOfBirth,
    };

    setCustomerProfileContextData({
      ...customerProfileContextData,
      ...formData,
      birthday: data.yearOfBirth.toString(),
    });

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.PersonalInformation,
        personalData: formData,
      },
      personId,
    });
  };

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <FormWrapper
      className={styles['personal-info-form']}
      dataTestId="personal-info-form"
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <h1 className={styles['personal-info-form__heading']}>{cmsContent.tabTitle}</h1>
      <SelectField isClearable label={cmsContent.prefix} name="prefix" options={prefixesOptions} />
      <FieldController<PersonalInfoFormData>
        label={cmsContent.firstName}
        name="firstName"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_NAME_REGEXP,
          },
        }}
      >
        <FormInput maxLength={MAX_FIRST_NAME_LENGTH} />
      </FieldController>
      <FieldController<PersonalInfoFormData>
        label={cmsContent.middleName}
        name="middleName"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_NAME_REGEXP,
          },
        }}
      >
        <FormInput maxLength={MAX_MIDDLE_NAME_LENGTH} />
      </FieldController>
      <FieldController<PersonalInfoFormData>
        label={cmsContent.lastName}
        name="lastName"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_NAME_REGEXP,
          },
          required: `${cmsContent.required}`,
        }}
      >
        <FormInput maxLength={MAX_LAST_NAME_LENGTH} />
      </FieldController>
      <SelectField isClearable label={cmsContent.suffix} name="suffix" options={suffixesOptions} />
      <FieldController<PersonalInfoFormData>
        label={cmsContent.preferredName}
        name="preferredName"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_NAME_REGEXP,
          },
        }}
      >
        <FormInput maxLength={MAX_PREFERRED_NAME_LENGTH} />
      </FieldController>
      <FieldController<PersonalInfoFormData>
        label={cmsContent.yearOfBirth}
        name="yearOfBirth"
        rules={{
          max: {
            message: `${cmsContent.yearOfBirthValidationMessage}`,
            value: maxYearOfBirth,
          },
          min: {
            message: `${cmsContent.yearOfBirthValidationMessage}`,
            value: minYearOfBirth,
          },
          required: cmsContent.required,
        }}
        transform={allowOnlyNumbersTransform}
      >
        <FormInput />
      </FieldController>
      <SelectField
        label={cmsContent.gender}
        name="gender"
        options={gendersOptions}
        rules={{
          required: cmsContent.required,
        }}
      />
      <div className={styles['personal-info-form__footer']}>
        <Button disabled={!isDirty || isMutationPending} type={ButtonType.SUBMIT}>
          {cmsContent.saveChanges}
        </Button>
      </div>
    </FormWrapper>
  );
};
