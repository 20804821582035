export const MIN_AGE = 18;
export const MAX_AGE = 120;
export const MAX_FIRST_NAME_LENGTH = 20;
export const MAX_MIDDLE_NAME_LENGTH = 20;
export const MAX_LAST_NAME_LENGTH = 30;
export const MAX_PREFERRED_NAME_LENGTH = 25;
export const MAX_USERNAME_LENGTH = 100;
export const MAX_BIO_LENGTH = 280;
export const MAX_PERSONAL_COMPANY_NAME_LENGTH = 100;
export const MAX_JOB_TITLE_LENGTH = 50;
export const MAX_AGENT_ID_LENGTH = 20;
export const MIN_YEAR_OF_FIRST_LICENSE = 100;
export const MAX_PHONE_NUMBER_LENGTH = 15;
export const MAX_AREA_CODE_LENGTH = 5;
export const MAX_WECHAT_ID_LENGTH = 20;
export const MAX_ADDRESS_LINE_LENGTH = 60;
export const MAX_CITY_LENGTH = 50;
export const MAX_ZIP_CODE_LENGTH = 25;
export const MAX_EMAIL_LENGTH = 255;
