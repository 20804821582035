'use client';

import { Dialog } from 'react-aria-components';

import { Chip, useGlobalCmsContentContext } from '@module/mdrt-org/shared/components';
import { Link } from '@module/mdrt-org/shared/i18n';
import { SEARCH_PERSON_PARAM_KEY } from '@module/mdrt-org/shared/utils/constants';
import { isTruthy } from '@module/mdrt-org/shared/utils/helpers';
import {
  Avatar,
  Button,
  ChipTheme,
  CloseIcon,
  EmailIcon,
  ModalTrigger,
  PersonBookIcon,
  RenderHTML,
} from '@shared/ui-components';
import {
  formatTranslationStringOnClient,
  GTM_EVENT_NAME_AUTHOR_RESOURCELIST_CLICK,
  GTM_BIO_TYPE_AUTHOR,
  GTM_BIO_TYPE_FEATURED,
  GTM_EVENT_NAME_AUTHOR_BIO_CLICK,
  sendGtmEvent,
} from '@shared/utils';

import { type PersonWithResourcesCount } from '../../types';

import styles from './person-details.module.scss';

export type PersonDetailsProps = {
  authorLinkAriaLabelText: string;
  authorModalResourcesButtonLabelText: string;
  isFeaturedPerson: boolean;
  memberDetailsButtonAriaLabelText: string;
  memberEmailButtonAriaLabelText: string;
  memberYearsSubheading: string | null | undefined;
  person: PersonWithResourcesCount;
  searchPagePath: string;
};

const getIsInModalString = (isInModal?: boolean) => (isInModal ? '-modal' : '');

export const PersonDetails = ({
  authorLinkAriaLabelText,
  authorModalResourcesButtonLabelText,
  isFeaturedPerson,
  memberDetailsButtonAriaLabelText,
  memberEmailButtonAriaLabelText,
  memberYearsSubheading,
  person,
  searchPagePath,
}: PersonDetailsProps) => {
  const imageSource = person.Image?.ImageReference?.Url ?? '';
  const imageAltText = person.Image?.AltText ?? '';
  const name = person.Name;
  const memberYears = person.MemberYears && person.MemberYears !== 'NM' ? person.MemberYears : null;
  const memberYearsText =
    memberYears &&
    (memberYearsSubheading || 'MDRT Member: {years} years').replaceAll('{years}', memberYears);
  const title = person.Title;
  const city = person.City;
  const country = person.Country;
  const cityAndCountry = [city, country].filter(isTruthy).join(', ');
  const emailAddress = person.EmailAddress;
  const showDescriptionButton = Boolean(person.Description);
  const authorLinkAriaLabel = formatTranslationStringOnClient(authorLinkAriaLabelText, {
    entity: name,
  });
  const linkToPersonSearchQuery = `${searchPagePath}?q=[${SEARCH_PERSON_PARAM_KEY}=${name}]`;

  const { cmsContent } = useGlobalCmsContentContext();

  if (!name) {
    return null;
  }

  const handleGtmAuthorResourceListEvent = () =>
    sendGtmEvent({ eventName: GTM_EVENT_NAME_AUTHOR_RESOURCELIST_CLICK, label: name });

  const renderAvatarComponent = (isInModal?: boolean) => (
    <Avatar
      alt={imageAltText}
      data-testid={`person-details${getIsInModalString(isInModal)}-avatar`}
      src={imageSource}
    />
  );

  const renderInfoComponent = (isInModal?: boolean) => {
    const isInModalString = getIsInModalString(isInModal);

    return (
      <div className={styles.info}>
        {isInModal ? (
          <h4 data-testid={`person-details${isInModalString}-name`}>{name}</h4>
        ) : (
          <Link
            aria-label={authorLinkAriaLabel}
            className={styles['author-link']}
            href={linkToPersonSearchQuery}
            onClick={handleGtmAuthorResourceListEvent}
          >
            <h4 data-testid={`person-details${isInModalString}-name`}>{name}</h4>
          </Link>
        )}

        {memberYearsText && (
          <p
            className={styles.member}
            data-testid={`person-details${isInModalString}-member-years`}
          >
            {memberYearsText}
          </p>
        )}
        {!memberYearsText && title && (
          <p className={styles.title} data-testid={`person-details${isInModalString}-title`}>
            {title}
          </p>
        )}
        {cityAndCountry && (
          <p className={styles.address} data-testid={`person-details${isInModalString}-address`}>
            {cityAndCountry}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['content-wrapper']}>
        {renderAvatarComponent(false)}
        <div className={styles.content}>
          {renderInfoComponent(false)}
          <div className={styles.actions}>
            {showDescriptionButton && (
              <ModalTrigger
                renderTrigger={({ handleModalOpen }) => (
                  <button
                    aria-label={`${memberDetailsButtonAriaLabelText} ${name}`}
                    className={styles.action}
                    data-testid="person-details-modal-trigger-button"
                    onClick={() => {
                      handleModalOpen();
                      sendGtmEvent({
                        author_bio_type: isFeaturedPerson
                          ? GTM_BIO_TYPE_FEATURED
                          : GTM_BIO_TYPE_AUTHOR,
                        eventName: GTM_EVENT_NAME_AUTHOR_BIO_CLICK,
                        label: name,
                      });
                    }}
                  >
                    <PersonBookIcon />
                  </button>
                )}
              >
                {(handleClose) => (
                  <Dialog>
                    <div className={styles['modal']}>
                      <div className={styles['modal__wrapper']}>
                        <div className={styles['modal__header']}>
                          {renderAvatarComponent(true)}
                          {renderInfoComponent(true)}
                          <Button
                            Icon={CloseIcon}
                            aria-label={cmsContent.closeButtonLabel}
                            className={styles['modal__close-button']}
                            dataTestId="person-details-modal-close-button"
                            onClick={handleClose}
                          />
                        </div>
                        <div className={styles.divider} />
                        <div
                          className={styles['modal__content']}
                          data-testid="person-details-modal-description"
                        >
                          <RenderHTML as="p" html={person.Description} />
                        </div>
                        <div className={styles.divider} />
                        <div className={styles['modal__footer']}>
                          {person.resourcesCount > 0 && (
                            <Chip
                              aria-label={authorLinkAriaLabel}
                              className={styles['modal__buttons']}
                              dataTestId="person-details-modal-resources-button"
                              label={`${authorModalResourcesButtonLabelText} ${person.resourcesCount}`}
                              onClick={handleGtmAuthorResourceListEvent}
                              theme={ChipTheme.Light}
                              to={linkToPersonSearchQuery}
                            />
                          )}

                          {emailAddress && (
                            <Chip
                              aria-label={`${memberEmailButtonAriaLabelText} ${name}`}
                              className={styles['modal__buttons']}
                              dataTestId="person-details-modal-email-button"
                              icon={<EmailIcon />}
                              isExternal
                              label={memberEmailButtonAriaLabelText}
                              theme={ChipTheme.Light}
                              to={`mailto:${emailAddress}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog>
                )}
              </ModalTrigger>
            )}
            {emailAddress && (
              <a
                aria-label={`${memberEmailButtonAriaLabelText} ${name}`}
                className={styles.action}
                data-testid="person-details-email"
                href={`mailto:${emailAddress}`}
              >
                <EmailIcon />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
