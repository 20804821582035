'use client';

import { Item as Section } from 'react-stately';

import {
  HashTabsNavigation,
  HashTabsNavigationSkeleton,
  type HashTabsNavigationItems,
} from '@shared/ui-components';
import { useMounted } from '@shared/utils';

export const AccountSettingsPageWrapper = ({
  hashTabsNavigationItems,
}: {
  hashTabsNavigationItems: HashTabsNavigationItems;
}) => {
  const isMounted = useMounted();
  if (!isMounted) return <HashTabsNavigationSkeleton />;

  return (
    <HashTabsNavigation items={hashTabsNavigationItems}>
      {({ formComponent }) => {
        return <Section>{formComponent && formComponent}</Section>;
      }}
    </HashTabsNavigation>
  );
};
