'use client';

import classnames from 'classnames';
import { type HTMLAttributes, useEffect, useRef } from 'react';

import { FormWrapper } from '@shared/ui-components';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';

import { FilterContent, FilterFooter, FilterHeader } from './components';
import { type AdvancedFilterFormData } from './types/advanced-filter-form-data';
import { type useSearchAdvancedFilter } from './use-search-advanced-filter';
import { getDefaultValueObjectFromFilterOptions } from './utils';
import styles from './search-advanced-filter.module.scss';

const FORM_ID = 'learn-filters-form';

type SearchAdvancedFilterProps = {
  filterState: ReturnType<typeof useSearchAdvancedFilter>;
  isMobile?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (formData: AdvancedFilterFormData) => void;
  searchCmsContent: SearchCmsContent;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSubmit' | 'onReset'>;

export const SearchAdvancedFilter = ({
  className,
  filterState,
  isMobile,
  isOpen,
  onClose,
  onReset,
  onSubmit,
  searchCmsContent,
  ...rest
}: SearchAdvancedFilterProps) => {
  const { filterOptions, formMethods } = filterState;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMobile && isOpen) {
      wrapperRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!filterOptions) {
    return null;
  }

  const handleReset = () => {
    formMethods.reset(getDefaultValueObjectFromFilterOptions(filterOptions, false));
    onReset();
  };

  return (
    <aside className={className} {...rest} ref={wrapperRef} tabIndex={-1}>
      <FilterHeader
        className={classnames(styles['filter-header'], {
          [styles['filter-header--mobile']]: isMobile,
        })}
        onClose={onClose}
        searchCmsContent={searchCmsContent}
      />
      <FormWrapper<AdvancedFilterFormData>
        className={classnames(styles.form, { [styles['form--mobile']]: isMobile })}
        dataTestId="advanced-filter-form"
        formMethods={formMethods}
        id={FORM_ID}
        onSubmit={(formData) => onSubmit(formData)}
      >
        <FilterContent
          filterOptions={filterOptions}
          searchPageCmsContent={searchCmsContent.searchPage}
        />
      </FormWrapper>
      <FilterFooter
        className={classnames({ [styles['filter-footer--mobile']]: isMobile })}
        formId={FORM_ID}
        handleReset={handleReset}
        searchCmsContent={searchCmsContent}
      />
    </aside>
  );
};
