import { CheckboxField } from '@shared/ui-components';

import {
  type AdvancedFilterOptions,
  type FilterSection,
} from '../../types/advanced-filter-form-data';

import styles from './filter-options.module.scss';

type FilterOptionsProps = {
  filterOptions: AdvancedFilterOptions[FilterSection];
  sectionName: FilterSection;
};

export const FilterOptions = ({ filterOptions, sectionName }: FilterOptionsProps) => (
  <ul>
    {filterOptions.map(({ count, displayName, name }) => (
      <li className={styles['filter-options-item']} key={name}>
        <CheckboxField
          label={`${displayName ?? name} (${count ?? 0})`}
          name={`${sectionName}.${name}`}
        />
      </li>
    ))}
  </ul>
);
