import {
  type PhoneNumber,
  APP_PHONE_TYPE_OPTIONS,
  APP_PHONE_TYPES,
  PhoneType,
} from '@module/mdrt-org/shared/utils/types';
import { type Country } from '@module/mdrt-org/shared/utils/types/countries';
import { type SelectOption } from '@shared/ui-components';

import { useAccountSettingsPageContext } from '../../providers/account-settings-page-context';
import { type ContactInformationPhoneNumberFormDataPartial } from '../../utils/types/form-data';

const sortBySelectedPhoneNumberTypeField = (
  firstPhoneNumber: ContactInformationPhoneNumberFormDataPartial,
  secondPhoneNumber: ContactInformationPhoneNumberFormDataPartial,
  selectedPhoneType: Nullable<PhoneType>
) =>
  firstPhoneNumber.phoneTypeOption?.value === selectedPhoneType
    ? -1
    : secondPhoneNumber.phoneTypeOption?.value === selectedPhoneType
    ? 1
    : 0;

const buildPhoneIsoCodeOptions = (countriesData: Country[]): Array<SelectOption<string>> => {
  const groupedOptions: Record<string, Country[]> = {};

  for (const country of countriesData) {
    (groupedOptions[country.telephoneCode] = groupedOptions[country.telephoneCode] || []).push(
      country
    );
  }

  const getCombinedCountryIsoCodes = (countryList: Country[]) =>
    countryList.map((country) => country.isoCode).join(' / ');

  return Object.values(groupedOptions)?.map((countryList) => ({
    label: `+${countryList[0].telephoneCode} ${getCombinedCountryIsoCodes(countryList)}`,
    value: countryList[0].telephoneCode,
  }));
};

const getDefaultPhoneIsoCodeOption = ({
  countriesData,
  countryId,
  phoneIsoCodeOptions,
}: {
  countriesData: Country[];
  countryId: Nullable<number>;
  phoneIsoCodeOptions: Array<SelectOption<string>>;
}) => {
  const businessAddressCountry = countriesData?.find((country) => country.countryId === countryId);

  return (
    phoneIsoCodeOptions.find(
      (option) => option && option.value === businessAddressCountry?.telephoneCode
    ) || null
  );
};

export const usePhoneSectionData = () => {
  const { countriesData, customerProfileContextData } = useAccountSettingsPageContext();

  const phoneIsoCodeOptions = buildPhoneIsoCodeOptions(countriesData);

  const defaultPhoneIsoCodeOption = getDefaultPhoneIsoCodeOption({
    countriesData,
    countryId: customerProfileContextData.countryId,
    phoneIsoCodeOptions,
  });

  const defaultPhoneNumber: ContactInformationPhoneNumberFormDataPartial = {
    phoneAreaCode: '',
    phoneIsoCodeOption: defaultPhoneIsoCodeOption,
    phoneNumber: '',
    phoneTypeOption: { label: PhoneType.CELL_PHONE, value: PhoneType.CELL_PHONE },
  };

  const mapPhoneNumberToFormPartial = (
    phoneItem: PhoneNumber
  ): ContactInformationPhoneNumberFormDataPartial => {
    const { areaCode, countryCode, phoneNumber, phoneType } = phoneItem;

    return {
      phoneAreaCode: areaCode ?? '',
      phoneIsoCodeOption: phoneIsoCodeOptions?.find((item) => item?.value === countryCode) ?? null,
      phoneNumber,
      phoneTypeOption: APP_PHONE_TYPE_OPTIONS?.find((item) => item?.value === phoneType) ?? {
        label: PhoneType.CELL_PHONE,
        value: PhoneType.CELL_PHONE,
      },
    };
  };

  const initialPhoneNumbers = customerProfileContextData.phoneNumbers
    ?.filter((item) => APP_PHONE_TYPES.includes(item.phoneType as PhoneType))
    .map(mapPhoneNumberToFormPartial)
    .sort((firstPhoneNumber, secondPhoneNumber) =>
      sortBySelectedPhoneNumberTypeField(
        firstPhoneNumber,
        secondPhoneNumber,
        customerProfileContextData.selectedPhoneType
      )
    );

  return {
    defaultPhoneNumber,
    initialPhoneNumbers:
      initialPhoneNumbers && initialPhoneNumbers?.length > 0
        ? initialPhoneNumbers
        : [defaultPhoneNumber],
    phoneIsoCodeOptions,
    phoneTypeOptions: APP_PHONE_TYPE_OPTIONS,
  };
};
