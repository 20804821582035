'use client';

import { type Node } from '@react-types/shared';
import { type RefObject, useEffect, useRef, useState } from 'react';
import {
  type AriaTabListProps,
  type AriaTabPanelProps,
  type AriaTabProps,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import { Item, type TabListState, useTabListState } from 'react-stately';

import { Button, useGlobalLoaderContext } from '@module/mdrt-org/shared/components';
import { HOMEPAGE_PATH } from '@module/mdrt-org/shared/utils/constants';
import { type PatchAssessmentQuestions } from '@module/mdrt-org/shared/utils/data/patch-assessment-questions';
import { AssessmentQuestionsTopic } from '@module/mdrt-org/shared/utils/enums/assessment-questions-topic';
import {
  useAssessmentQuestionsMutation,
  useConfirmNavigation,
  useMutationHandler,
} from '@module/mdrt-org/shared/utils/hooks';
import { type SelfAssessment } from '@module/mdrt-org/shared/utils/types';
import { Alert, ArrowIcon, ButtonVariant } from '@shared/ui-components';

import { type PersonQuizPageContent } from './components/quiz-page-content-wrapper/get-person-quiz-page-content';
import { TabPanelSkeleton } from './components/quiz-page-skeleton-loader/quiz-page-skeleton-loader';
import { QuizTabItem } from './components/quiz-tabs-navigation';
import { SelfAssessmentForm } from './components/self-assessment-form';
import { getInitialSelfAssessmentFormData } from './components/self-assessment-form/helpers';
import styles from './quiz-page-content-tabs.module.scss';

type TabData = {
  assessmentScore: number;
  currentTopicData:
    | {
        questions: Array<{
          question: string;
          questionId: string;
          score: number;
        }>;
        topic: AssessmentQuestionsTopic;
        topicScore: number;
      }
    | undefined;
  tabDescription: string | null | undefined;
  tabTitle: string | null | undefined;
  topic: AssessmentQuestionsTopic;
};

type TabItemProps = AriaTabProps & TabData;

const Tab = ({
  item,
  state,
  topicContainerRef,
}: {
  item: Node<TabItemProps>;
  state: TabListState<TabItemProps>;
  topicContainerRef: RefObject<HTMLDivElement>;
}) => {
  const { key, value } = item;
  const ref = useRef<HTMLButtonElement | null>(null);
  const { isSelected, tabProps } = useTab({ key }, state, ref);

  if (value === null) {
    return null;
  }

  return (
    <QuizTabItem
      assessmentScore={value.assessmentScore}
      buttonProps={tabProps}
      isActive={isSelected}
      key={value.key}
      onClick={() => {
        // Timeouts are added to fix iOS issue.
        setTimeout(() => {
          ref.current?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
        }, 0);

        setTimeout(() => {
          topicContainerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 0);
      }}
      ref={ref}
      scoreLabel={value?.tabTitle}
    />
  );
};

const TabPanel = ({
  state,
  ...props
}: AriaTabPanelProps & { state: TabListState<TabItemProps> }) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <div
      className={styles['topic-section']}
      data-testid="quiz-tab-content"
      {...tabPanelProps}
      ref={ref}
      tabIndex={0}
    >
      {state.selectedItem ? state.selectedItem.props.children : <TabPanelSkeleton />}
    </div>
  );
};

const Tabs = (
  props: AriaTabListProps<TabItemProps> & {
    abortRequest: () => void;
    isMutationPending: boolean;
    relativePath: string;
  }
) => {
  const { abortRequest, isMutationPending, relativePath, ...restProps } = props;
  const state = useTabListState(restProps);
  const ref = useRef(null);
  const topicContainerRef = useRef<HTMLDivElement>(null);

  const setActiveTab = (dataKey: string) => state.setSelectedKey(dataKey);
  useConfirmNavigation(isMutationPending, relativePath, { abortRequest, onConfirm: setActiveTab });

  const { tabListProps } = useTabList(props, state, ref);

  return (
    <>
      <div
        className={styles['quiz-tabs-list']}
        data-testid="quiz-tabs-list"
        {...tabListProps}
        ref={ref}
      >
        {[...state.collection].map((item: Node<TabItemProps>) => (
          <Tab item={item} key={item.key} state={state} topicContainerRef={topicContainerRef} />
        ))}
      </div>
      <div className={styles['topic-section-container']} ref={topicContainerRef}>
        <TabPanel key={state.selectedItem?.key} state={state} />
      </div>
    </>
  );
};

export const QuizPageContentTabs = ({
  cmsContent,
  initialSelfAssessmentData,
  relativePath,
}: {
  cmsContent: PersonQuizPageContent;
  initialSelfAssessmentData: SelfAssessment;
  relativePath: string;
}) => {
  const assessmentQuestionsMutation = useAssessmentQuestionsMutation();

  const [selfAssessmentFormData, setSelfAssessmentFormData] = useState(() =>
    getInitialSelfAssessmentFormData(initialSelfAssessmentData, cmsContent)
  );

  const handleAssessmentQuestionsSubmit = async (formData: PatchAssessmentQuestions) => {
    return await assessmentQuestionsMutation.mutate(formData);
  };

  const cancelMutation = () => {
    assessmentQuestionsMutation.cancel();
    setSelfAssessmentFormData(
      getInitialSelfAssessmentFormData(initialSelfAssessmentData, cmsContent)
    );
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: handleAssessmentQuestionsSubmit,
    scrollToTop: false,
    showSuccessToast: false,
  });

  const { setIsNonBlockingLoaderVisible } = useGlobalLoaderContext();

  const tabsData: TabItemProps[] = Object.values(AssessmentQuestionsTopic).map((topic) => {
    return {
      assessmentScore:
        selfAssessmentFormData?.assessmentTopics?.find((item) => item?.topic === topic)
          ?.topicScore ?? 0,
      currentTopicData: selfAssessmentFormData?.assessmentTopics?.find(
        (topicItem) => topicItem.topic === topic
      ),
      key: topic,
      tabDescription: cmsContent?.[topic]?.TopicDescription,
      tabTitle: cmsContent?.[topic]?.Title,
      topic,
    };
  });

  useEffect(() => {
    setIsNonBlockingLoaderVisible(isMutationPending);
  }, [isMutationPending, setIsNonBlockingLoaderVisible]);

  return (
    <>
      <div className={styles['quiz-page']} data-testid="quiz-description">
        <Button
          dataTestId="back-to-dashboard-link"
          isSmall
          prefix={<ArrowIcon className={styles['quiz-page__button-icon']} />}
          to={HOMEPAGE_PATH}
          variant={ButtonVariant.Tertiary}
        >
          {cmsContent.backToDashboard}
        </Button>
        <div className={styles['quiz-page__description-container']}>
          <h1 data-testid="quiz-page-heading">{cmsContent.title}</h1>
          <p className={styles['quiz-page__text']}>{cmsContent.description}</p>
        </div>
      </div>
      <Tabs
        abortRequest={cancelMutation}
        aria-label={cmsContent.title}
        isMutationPending={isMutationPending}
        items={tabsData}
        orientation="horizontal"
        relativePath={relativePath}
      >
        {(itemData) => (
          <Item>
            <div className={styles['topic-section-header']}>
              <h2 className={styles['topic-section-header__heading']}>{itemData.tabTitle}</h2>
              <p className={styles['topic-section-header__text']}>{itemData.tabDescription}</p>
              <Alert message={cmsContent.sliderInfo} />
            </div>
            <SelfAssessmentForm
              mutateAssessmentQuestions={mutate}
              selfAssessmentFormData={selfAssessmentFormData}
              setSelfAssessmentFormData={setSelfAssessmentFormData}
              topic={itemData.topic}
            />
          </Item>
        )}
      </Tabs>
    </>
  );
};
