import { type ChangeEvent } from 'react';

import { BIO_REGEXP } from '@module/mdrt-org/shared/utils/types';
import { type Transform } from '@shared/ui-components';

export const allowedCharactersTransform: Transform<HTMLTextAreaElement> = {
  input: (value: string) => {
    return typeof value === 'string' ? value.replace(BIO_REGEXP, '') : value;
  },
  output: (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    return value.replace(BIO_REGEXP, '');
  },
};
