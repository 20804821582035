'use client';

import { useUserContext } from '@module/mdrt-org/shared/components';
import { Alert } from '@module/mdrt-org/shared/components/alert';
import { useSessionStorageState } from '@module/mdrt-org/shared/utils/hooks';
import { AlertVariant } from '@shared/ui-components';
import { LOGIN_ALERT_HAS_BEEN_CLOSED_BY_USER_KEY } from '@shared/utils';

type LoginAlertProps = {
  className?: string;
  isSearchUpdated: boolean;
  message: string;
  totalResults: number;
};

export const LoginAlert = ({
  className,
  isSearchUpdated,
  message,
  totalResults,
}: LoginAlertProps) => {
  const [sessionValue, setSessionValue] = useSessionStorageState({
    defaultValue: 'false',
    key: LOGIN_ALERT_HAS_BEEN_CLOSED_BY_USER_KEY,
  });
  const { status } = useUserContext();
  const showAlert =
    isSearchUpdated && status === 'unauthenticated' && sessionValue !== 'true' && totalResults > 0;

  const handleAlertClose = () => {
    setSessionValue('true');
  };

  return (
    <>
      {showAlert && (
        <Alert
          className={className}
          data-testid="search-alert-for-not-loged-users"
          message={message}
          onClose={handleAlertClose}
          variant={AlertVariant.Informational}
        />
      )}
    </>
  );
};
