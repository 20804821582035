import Skeleton from 'react-loading-skeleton';

import styles from './quiz-page-skeleton-loader.module.scss';

export const TabPanelSkeleton = () => (
  <div className={styles['tab-panel-skeleton']}>
    <Skeleton className={styles['tab-panel-skeleton__heading']} />
    <Skeleton className={styles['tab-panel-skeleton__text']} count={1.8} />
    <Skeleton className={styles['tab-panel-skeleton__text']} count={2.5} />
    <Skeleton className={styles['tab-panel-skeleton__text']} count={2.3} />
    {[1, 2, 3, 4, 5, 6].map((key) => (
      <div key={key}>
        <Skeleton className={styles['tab-panel-skeleton__question']} count={2} />
        <Skeleton className={styles['tab-panel-skeleton__slider']} />
      </div>
    ))}
  </div>
);

const QuizPageTopSkeleton = () => (
  <>
    <div className={styles['page-top-skeleton']}>
      <Skeleton className={styles['page-top-skeleton__button']} />
      <Skeleton className={styles['page-top-skeleton__heading']} />
      <Skeleton className={styles['page-top-skeleton__text']} count={3.4} />
    </div>
    <div className={styles['page-top-skeleton-tabs-section']}>
      {[1, 2, 3, 4, 5, 6, 7].map((key) => (
        <Skeleton className={styles['page-top-skeleton-tabs-section__tab']} key={key} />
      ))}
    </div>
  </>
);

export const QuizPageSkeletonLoader = () => {
  return (
    <div>
      <QuizPageTopSkeleton />
      <TabPanelSkeleton />
    </div>
  );
};
