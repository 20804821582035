import { type LearnSearchResults } from '@module/mdrt-org/shared/utils/data/graphql';

import { type useSearchPageSearchParameters } from '../../../utils/hooks/use-search-page-search-parameters';
import {
  type FilterSection,
  type AdvancedFilterFormData,
  type AdvancedFilterOptions,
  type FilterOption,
} from '../types/advanced-filter-form-data';

type LearnSearchFacetField = Exclude<
  LearnSearchResults['facets'],
  null | undefined
>['DisplayedDateYear'];

const transformFacetItemsToFilterOptions = (facetItems: LearnSearchFacetField) => {
  const filterOptions = (facetItems || []).map((item) => {
    if (!item || !item.name || !item.count) {
      return null;
    }

    return {
      count: item.count,
      defaultValue: false,
      name: item.name,
    };
  });
  return filterOptions.filter(Boolean) as FilterOption[];
};

const addHistoricFilterOption = (filterOptions: FilterOption[], optionName: string) => {
  filterOptions.push({ count: 0, defaultValue: true, name: optionName });
};

const addHistoricOptionsFromFilterState = (
  filterOptions: FilterOption[],
  sectionState: AdvancedFilterFormData[FilterSection] | undefined
) => {
  const checkedOptions = Object.entries(sectionState || {}).filter(([, value]) => Boolean(value));

  for (const [name] of checkedOptions) {
    const existingOption = filterOptions.find((option) => option.name === name);

    if (existingOption) {
      existingOption.defaultValue = true;
    } else {
      addHistoricFilterOption(filterOptions, name);
    }
  }

  return filterOptions;
};

const addHistoricOptionsFromAppliedSearchParameters = (
  filterOptions: FilterOption[],
  searchParameterValue: string[] | undefined
) => {
  for (const optionName of searchParameterValue || []) {
    const option = filterOptions.find(({ name }) => name === optionName);
    if (option) {
      option.defaultValue = true;
    } else {
      addHistoricFilterOption(filterOptions, optionName);
    }
  }

  return filterOptions;
};

const getFilterSectionOptions = ({
  facetItems,
  searchParameterValue,
  sectionState,
}: {
  facetItems: LearnSearchFacetField;
  searchParameterValue: string[] | undefined;
  sectionState: AdvancedFilterFormData[FilterSection] | undefined;
}) => {
  let options = transformFacetItemsToFilterOptions(facetItems);
  options = addHistoricOptionsFromFilterState(options, sectionState);
  options = addHistoricOptionsFromAppliedSearchParameters(options, searchParameterValue);
  return options;
};

const getLocalCalendarYear = ({
  gregorianYear,
  yearFormatter,
}: {
  gregorianYear: string;
  yearFormatter: Intl.DateTimeFormat;
}): string => {
  const yearDisplayString = yearFormatter.format(new Date(Number(gregorianYear), 0));

  const yearString = yearDisplayString.match(/\d+/u)?.[0];
  if (!yearString) {
    return gregorianYear;
  }

  return yearString;
};

export const getFilterOptionsWithDefaultValues = ({
  facets,
  formState,
  searchParameters,
  locale,
}: {
  facets: LearnSearchResults['facets'];
  formState: AdvancedFilterFormData | undefined;
  locale: string;
  searchParameters: ReturnType<typeof useSearchPageSearchParameters>;
}): AdvancedFilterOptions => {
  const yearFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

  return {
    source: getFilterSectionOptions({
      facetItems: facets?.ExpandedSources?.Name,
      searchParameterValue: searchParameters.source,
      sectionState: formState?.source,
    }),
    topic: getFilterSectionOptions({
      facetItems: facets?.ExpandedTopics?.Name,
      searchParameterValue: searchParameters.topic,
      sectionState: formState?.topic,
    }),
    year: getFilterSectionOptions({
      facetItems: facets?.DisplayedDateYear,
      searchParameterValue: searchParameters.year,
      sectionState: formState?.year,
    })
      .sort((optionA, optionB) => Number(optionB.name) - Number(optionA.name))
      .map((yearFilterOption) => ({
        ...yearFilterOption,
        displayName: getLocalCalendarYear({
          gregorianYear: yearFilterOption.name,
          yearFormatter,
        }),
      })),
  };
};
