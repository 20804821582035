'use client';

import { useFormContext } from 'react-hook-form';

import { Chip, useUserContext } from '@module/mdrt-org/shared/components';
import { Alert, AlertVariant, ChipTheme, FileInput, NoPhoto } from '@shared/ui-components';
import {
  type ServerActionResponse,
  PICTURE_UPLOAD_ERROR_ID,
  useProfilePictureUpload,
} from '@shared/utils';

import { type ProfileFormData } from '../../utils/types/form-data';

import styles from './profile-photo-upload.module.scss';

type ProfilePhotoUploadProps = {
  cmsContent: {
    add: string;
    change: string;
    fileSizeError: string;
    fileTypeError: string;
    pictureDescription: string;
    pictureFileInputHint: string;
    remove: string;
  };
  name: string;
  onPhotoDelete: ({ personId }: { personId: number }) => Promise<ServerActionResponse>;
  profilePicture: Nullable<string> | undefined;
};

export const ProfilePhotoUpload = ({
  cmsContent,
  name,
  onPhotoDelete,
  profilePicture,
}: ProfilePhotoUploadProps) => {
  const { personId } = useUserContext();
  const {
    formState: { errors },
    resetField,
  } = useFormContext<ProfileFormData>();
  const profilePictureError = errors.file?.message;

  const { previewPictureSource, rules, updatePreviewImageSource } = useProfilePictureUpload({
    fileSizeErrorMessage: cmsContent.fileSizeError,
    fileTypeErrorMessage: cmsContent.fileTypeError,
    initialProfilePicture: profilePicture ?? undefined,
  });

  const handlePictureDelete = async () => {
    if (profilePicture && personId) {
      const result = await onPhotoDelete({ personId });

      if (result.status === 200) {
        resetField('file');
        updatePreviewImageSource(undefined);
      }

      return;
    }

    resetField('file');
    updatePreviewImageSource(undefined);
  };

  return (
    <>
      <div className={styles['photo-section']}>
        <div className={styles['photo-section__placeholder']}>
          {previewPictureSource ? (
            <img
              alt={cmsContent.pictureDescription}
              className={styles['photo-section__photo']}
              data-testid="photo-section-photo"
              src={previewPictureSource}
            />
          ) : (
            <NoPhoto className={styles['photo-section__icon']} data-testid="photo-section-icon" />
          )}
        </div>
        <div className={styles['photo-section__group']}>
          <FileInput
            asChip
            dataTestId="photo-section-file-upload"
            hasError={profilePictureError !== undefined}
            label={previewPictureSource ? cmsContent.change : cmsContent.add}
            name={name}
            onChange={updatePreviewImageSource}
            rules={rules}
            theme={ChipTheme.Light}
          />
          {profilePicture || previewPictureSource ? (
            <Chip
              dataTestId="photo-section-remove-photo"
              label={cmsContent.remove}
              onClick={handlePictureDelete}
              theme={ChipTheme.Light}
            />
          ) : (
            <p className={styles['photo-section__description']}>
              {cmsContent.pictureFileInputHint}
            </p>
          )}
        </div>
      </div>
      {profilePictureError && (
        <Alert
          id={PICTURE_UPLOAD_ERROR_ID}
          message={profilePictureError}
          variant={AlertVariant.Error}
        />
      )}
    </>
  );
};
