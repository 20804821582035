'use client';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { SelectField, useUserContext } from '@module/mdrt-org/shared/components';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { useConfirmNavigation, useMutationHandler } from '@module/mdrt-org/shared/utils/hooks';
import {
  type CenterCommunicationTopics,
  type CommunicationTopics,
} from '@module/mdrt-org/shared/utils/types';
import { Button, CheckboxField, FormWrapper, type SelectOption } from '@shared/ui-components';
import { ButtonType, formBaseConfig, type ServerActionResponse } from '@shared/utils';

import { useAccountSettingsPageContext } from '../../providers/account-settings-page-context';
import { useAddAccountSettingsErrorToast } from '../../utils/helpers/add-account-settings-error-toast';
import { type SitePreferencesData, type SitePreferencesFormData } from '../../utils/types';

import { type AccountSettingsSitePreferencesContent } from './get-account-settings-site-preferences-content';
import { InterestsSection } from './interests-section/interests-section';
import { type InterestsData } from './site-preferences-server';
import { CommunicationPreferenceCenterTypes } from './utils/get-current-preference-center';
import styles from './site-preferences.module.scss';

type SitePreferencesFormProps = {
  cmsContent: AccountSettingsSitePreferencesContent;
  languagesOptions: SelectOption[];
  tabUrl: string;
  topics: InterestsData;
};

type CommunicationTopicKeys = keyof CommunicationTopics;
type CenterCommunicationTopicsKeys = keyof CenterCommunicationTopics;

export const SitePreferencesForm = ({
  cmsContent,
  languagesOptions,
  tabUrl,
  topics,
}: SitePreferencesFormProps) => {
  const {
    communicationPreferencesContextData,
    customerProfileContextData,
    resetCommunicationPreferencesContextData,
    resetCustomerProfileContextData,
    setCommunicationPreferencesContextData,
    setCustomerProfileContextData,
  } = useAccountSettingsPageContext();
  const { personId } = useUserContext();
  const addErrorToast = useAddAccountSettingsErrorToast();

  const interestsSectionCmsContent = {
    addMoreTopicsButtonLabel: cmsContent.addMoreTopicsButtonLabel,
    cancelButtonLabel: cmsContent.cancelButtonLabel,
    description: cmsContent.selectTopics,
    interestModalSubTitle: cmsContent.interestModalSubTitle,
    interestModalTitle: cmsContent.interestModalTitle,
    interestsErrorMessage: cmsContent.interestsErrorMessage,
    saveButtonLabel: cmsContent.interestModalSaveButtonLabel,
    title: cmsContent.interestsTitle,
  };

  const formMethods = useForm<SitePreferencesFormData>({
    ...formBaseConfig,
    defaultValues: {
      centerCommunicationTopics: communicationPreferencesContextData.centerCommunicationTopics,
      communicationCenter: communicationPreferencesContextData.communicationCenter,
      communicationTopics: communicationPreferencesContextData.communicationTopics,
      contactPermission: communicationPreferencesContextData.contactPermission,
      interests: customerProfileContextData.interests
        ? topics.filter((topic) => customerProfileContextData.interests.includes(topic.topicId))
        : [],
      memberDirectoryOptIn: customerProfileContextData.memberDirectoryOptIn,
      preferredLanguage: customerProfileContextData.primaryLanguage
        ? {
            label: languagesOptions.find(
              (language) => language?.value === customerProfileContextData.primaryLanguage
            )?.label,
            value: customerProfileContextData.primaryLanguage,
          }
        : null,
    },
  });

  const {
    formState: { isDirty },
    reset,
  } = formMethods;

  const onFailure = (result: ServerActionResponse) => {
    if (result.status !== 200 && 'message' in result) {
      resetCustomerProfileContextData();
      resetCommunicationPreferencesContextData();
      addErrorToast();
    }
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<SitePreferencesData>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const emailNotifications: Array<{
    label: string;
    name:
      | `communicationTopics.${CommunicationTopicKeys}`
      | `centerCommunicationTopics.${CenterCommunicationTopicsKeys}`;
  }> =
    communicationPreferencesContextData.communicationCenter ===
    CommunicationPreferenceCenterTypes.MDRT
      ? [
          {
            label: cmsContent.memberBenefitsLabel,
            name: 'communicationTopics.memberBenefits',
          },
          {
            label: cmsContent.contentNewsletterLabel,
            name: 'communicationTopics.contentNewsletter',
          },
          {
            label: cmsContent.MDRTMeetingsLabel,
            name: 'communicationTopics.mdrtMeetings',
          },
          {
            label: cmsContent.businessAnnouncementsLabel,
            name: 'communicationTopics.businessAnnouncements',
          },
          {
            label: cmsContent.MDRTFoundationLabel,
            name: 'communicationTopics.mdrtFoundation',
          },
          {
            label: cmsContent.MDRTStoreLabel,
            name: 'communicationTopics.mdrtStore',
          },
        ]
      : communicationPreferencesContextData.communicationCenter ===
        CommunicationPreferenceCenterTypes.Center
      ? [
          {
            label: cmsContent.memberBenefitsLabel,
            name: 'centerCommunicationTopics.memberBenefits',
          },
          {
            label: cmsContent.contentNewsletterLabel,
            name: 'centerCommunicationTopics.contentNewsletter',
          },
          {
            label: cmsContent.MDRTMeetingsLabel,
            name: 'centerCommunicationTopics.mdrtMeetings',
          },
          {
            label: cmsContent.businessAnnouncementsLabel,
            name: 'centerCommunicationTopics.businessAnnouncements',
          },
        ]
      : [];

  const handleSubmit = async (data: SitePreferencesFormData) => {
    setCustomerProfileContextData({
      ...customerProfileContextData,
      interests: data.interests && data.interests.map((interest) => interest.topicId),
      memberDirectoryOptIn: data.memberDirectoryOptIn,
      primaryLanguage: data.preferredLanguage && Number(data.preferredLanguage.value),
    });

    setCommunicationPreferencesContextData({
      centerCommunicationTopics:
        communicationPreferencesContextData.communicationCenter ===
        CommunicationPreferenceCenterTypes.Center
          ? data.communicationTopics
          : null,
      communicationCenter: data.communicationCenter,
      communicationTopics:
        communicationPreferencesContextData.communicationCenter ===
        CommunicationPreferenceCenterTypes.MDRT
          ? data.communicationTopics
          : null,
      contactPermission: data.contactPermission,
    });

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.SitePreferences,
        permissionData: {
          centerCommunicationTopics:
            communicationPreferencesContextData.communicationCenter ===
            CommunicationPreferenceCenterTypes.Center
              ? data.centerCommunicationTopics
              : null,
          communicationCenter: data.communicationCenter,
          communicationTopics:
            communicationPreferencesContextData.communicationCenter ===
            CommunicationPreferenceCenterTypes.MDRT
              ? data.communicationTopics
              : null,
          contactPermission: data.contactPermission,
        },
        sitePreferenceData: {
          interests: data.interests && data.interests.map((interest) => interest.topicId),
          memberDirectoryOptIn: data.memberDirectoryOptIn,
          preferredLanguageId: data.preferredLanguage && Number(data.preferredLanguage.value),
        },
      },
      personId,
    });
  };

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <FormWrapper
      className={styles['site-preferences-form']}
      dataTestId="site-preferences-form"
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <div>
        <h2 className={styles['site-preferences-form__heading']}>{cmsContent.languageLabel}</h2>
        <p className={styles['site-preferences-form__sub-heading']}>{cmsContent.chooseLanguage}</p>

        <SelectField
          label={cmsContent.preferredLanguageLabel}
          name="preferredLanguage"
          options={languagesOptions}
          rules={{
            required: cmsContent.requiredLabel,
          }}
        />
      </div>
      <hr className={styles['site-preferences-form__separator']} />
      <InterestsSection allTopics={topics} cmsContent={interestsSectionCmsContent} />
      <hr className={styles['site-preferences-form__separator']} />
      <h2 className={styles['site-preferences-form__heading']}>
        {cmsContent.emailNotificationLabel}
      </h2>
      <div>
        <p className={styles['site-preferences-form__content']}>
          {cmsContent.emailNotificationInformationLabel}
        </p>
        <fieldset className={styles['site-preferences-form__fieldset']}>
          <legend className={styles['site-preferences-form__legend']}>
            {cmsContent.communicationTopicInformationLabel}
          </legend>
          {emailNotifications.map((emailNotification) => {
            return (
              <CheckboxField
                key={emailNotification.label}
                label={emailNotification.label}
                name={emailNotification.name}
              />
            );
          })}
        </fieldset>
        <p
          className={classNames(
            styles['site-preferences-form__content'],
            styles['site-preferences-form__content--reduce-spacing']
          )}
        >
          {cmsContent.communicationRemovalInformationLabel}
        </p>
      </div>
      <hr className={styles['site-preferences-form__separator']} />
      <h2 className={styles['site-preferences-form__heading']}>
        {cmsContent.SMSNotificationLabel}
      </h2>
      <div className={styles['site-preferences-form__row']}>
        <CheckboxField
          label={cmsContent.SMSNotificationPermissionLabel}
          name="contactPermission.smsPermission"
        />
      </div>
      <hr className={styles['site-preferences-form__separator']} />
      <h2 className={styles['site-preferences-form__heading']}>
        {cmsContent.memberDirectoryTitle}
      </h2>
      <CheckboxField label={cmsContent.memberDirectoryLabel} name="memberDirectoryOptIn" />
      {/* ------------- */}
      <div className={styles['site-preferences-form__footer']}>
        <Button disabled={!isDirty || isMutationPending} type={ButtonType.SUBMIT}>
          {cmsContent.saveChangesButtonLabel}
        </Button>
      </div>
    </FormWrapper>
  );
};
