import { EMPTY_SYMBOL } from '../../constants';

export const formatDateYearMonthDay = (dateTime: string | number | Date | null | undefined) => {
  if (dateTime) {
    const date = new Date(dateTime);
    const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

    return new Date(utcDate).toISOString().split('T')[0];
  }

  return EMPTY_SYMBOL;
};
