import { SEARCH_FACET_LIMIT, SEARCH_LIMIT_PER_PAGE } from '@module/mdrt-org/shared/utils/constants';
import { SUPPORTED_RESOURCE_CONTENT_TYPES } from '@module/mdrt-org/shared/utils/data/constants';
import {
  type LearnSearchResultsQueryVariables,
  OrderBy,
  Ranking,
} from '@module/mdrt-org/shared/utils/data/graphql/_generated/gql-generated';
import { getCommonSearchQueryFilters } from '@module/mdrt-org/shared/utils/data/graphql/utils';

import { type getNormalizedSearchPageSearchParameters } from './get-normalized-search-page-search-parameters';

export const getSearchVariables = async (
  searchParameters: ReturnType<typeof getNormalizedSearchPageSearchParameters>,
  xForwardedForHeader: string
): Promise<LearnSearchResultsQueryVariables> => {
  const { pageIndex, contentType, searchPerson, searchTerm, year, source, topic } =
    searchParameters;
  const personWhere = {
    _or: [
      {
        ExpandedAuthors: {
          Name: {
            eq: searchPerson,
          },
        },
      },
      {
        ExpandedFeaturedPeople: {
          Name: {
            eq: searchPerson,
          },
        },
      },
    ],
  };

  return {
    facetLimit: SEARCH_FACET_LIMIT,
    limit: SEARCH_LIMIT_PER_PAGE,
    orderBy: { _ranking: Ranking.Relevance, DisplayedDate: OrderBy.Desc },
    skip: pageIndex * SEARCH_LIMIT_PER_PAGE,
    source,
    topic,
    where: {
      ...(await getCommonSearchQueryFilters({ xForwardedForHeader })),
      ...(searchTerm && {
        _fulltext: {
          match: searchTerm,
        },
      }),
      ...(searchPerson && personWhere),
      ContentTypeSelection: contentType
        ? { eq: contentType }
        : { in: SUPPORTED_RESOURCE_CONTENT_TYPES },
    },
    year,
  };
};
