/* eslint-disable typescript-sort-keys/string-enum */

/* UI is dependant on the order of this enum */
export enum AssessmentQuestionsTopic {
  relationships = 'Relationships',
  health = 'Health',
  education = 'Education',
  career = 'Career',
  service = 'Service',
  financial = 'Financial',
  spiritual = 'Spiritual',
}
