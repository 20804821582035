'use client';

import { useSearchParams } from 'next/navigation';
import { type ChangeEvent, type KeyboardEvent, useEffect, useRef, useState } from 'react';

import { SEARCH_QUERY_PARAM_KEY } from '@module/mdrt-org/shared/utils/constants';
import { CloseIcon, Input, MagnifierIcon } from '@shared/ui-components';
import { KeyboardKey } from '@shared/utils';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';
import { type SearchPageSearchParameters } from '../../types';

import { SearchSuggestions } from './components';
import styles from './search-bar.module.scss';

type SearchBarProps = {
  onSearchQueryChange: (searchParameters: SearchPageSearchParameters) => void;
  searchPageCmsContent: SearchCmsContent['searchPage'];
};

export const SearchBar = ({ onSearchQueryChange, searchPageCmsContent }: SearchBarProps) => {
  const searchParameters = useSearchParams();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchQueryValue, setSearchQueryValue] = useState(
    searchParameters.get(SEARCH_QUERY_PARAM_KEY) ?? ''
  );

  useEffect(() => {
    setSearchQueryValue(searchParameters.get(SEARCH_QUERY_PARAM_KEY) ?? '');
  }, [searchParameters]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchQueryValue(event.target.value);

  const handleSearchQueryClear = () => {
    setSearchQueryValue('');
    inputRef.current?.focus();

    if (searchParameters.get(SEARCH_QUERY_PARAM_KEY)) {
      onSearchQueryChange({ [SEARCH_QUERY_PARAM_KEY]: '' });
    }
  };

  const submitSearchQuery = () => {
    onSearchQueryChange({ [SEARCH_QUERY_PARAM_KEY]: searchQueryValue ?? '' });
    inputRef.current?.blur();
  };

  const handleSuggestionClick = (title: string) => {
    setSearchQueryValue(title);
    onSearchQueryChange({ [SEARCH_QUERY_PARAM_KEY]: title });
  };

  return (
    <div className={styles.wrapper}>
      <form
        action={submitSearchQuery}
        className={styles['search-bar']}
        key={searchParameters.get(SEARCH_QUERY_PARAM_KEY)}
      >
        <Input
          autoComplete="off"
          className={styles.input}
          data-testid="learn-search-input"
          leadingIcon={<MagnifierIcon className={styles['input-icon']} />}
          name="learn-search-input"
          onChange={handleInputChange}
          placeholder={searchPageCmsContent?.SearchInputPlaceholder || 'Search resources'}
          ref={inputRef}
          value={searchQueryValue}
        />
        <div className={styles['buttons-container']}>
          {searchQueryValue && (
            <button
              aria-label={searchPageCmsContent?.SearchInputClearButtonLabel || 'Clear button'}
              className={styles['clear-button']}
              onClick={handleSearchQueryClear}
              type="button"
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </form>
      <SearchSuggestions onSuggestionClick={(title) => handleSuggestionClick(title)} />
    </div>
  );
};
