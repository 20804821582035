'use client';

import { type ComponentProps, useMemo } from 'react';

import { SEARCH_PAGE_PARAM_KEY } from '@module/mdrt-org/shared/utils/constants';
import { Pagination } from '@shared/ui-components';
import { usePageScroll } from '@shared/utils';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';
import { type SearchPageSearchParameters } from '../../types';

import styles from './search-pagination.module.scss';

type SearchPaginationProps = {
  genericCmsContent: SearchCmsContent['generic'];
  onPageChange: (searchParameters: SearchPageSearchParameters) => void;
  pageIndex: number;
  totalCount: number;
};

export const SearchPagination = ({
  genericCmsContent,
  onPageChange,
  pageIndex,
  totalCount,
}: SearchPaginationProps) => {
  const page = pageIndex + 1;

  usePageScroll(page);

  const handlePageChange = (nextPage: number) => {
    const nextPageIndex = nextPage - 1;
    const parameterValue = nextPageIndex > 0 ? nextPageIndex.toString() : '';
    onPageChange({ [SEARCH_PAGE_PARAM_KEY]: parameterValue });
  };

  const labelMap: ComponentProps<typeof Pagination>['label'] = useMemo(
    () => ({
      nextPage: genericCmsContent.nextPageLabel,
      pagination: genericCmsContent.pagination,
      previousPage: genericCmsContent.previousPageLabel,
      totalItemsCount: genericCmsContent.totalItemsCountLabel,
    }),
    [genericCmsContent]
  );

  return (
    <Pagination
      blurButtonsOnPageChange
      className={styles['search-pagination']}
      currentPage={page}
      label={labelMap}
      onPageChange={handlePageChange}
      totalItemsCount={totalCount}
    />
  );
};
