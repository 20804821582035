'use client';

import { useGlobalCmsContentContext } from '@module/mdrt-org/shared/components';
import { ToastType, useGlobalToastContext } from '@shared/ui-components';

export const useAddAccountSettingsErrorToast = () => {
  const { addToast } = useGlobalToastContext();
  const { cmsContent: globalCmsContent } = useGlobalCmsContentContext();

  return () => {
    addToast(
      {
        closeButtonLabel: globalCmsContent.closeButtonLabel,
        description: globalCmsContent.somethingWentWrong,
        type: ToastType.ERROR,
      },
      { timeout: 0 }
    );
  };
};
