import classNames from 'classnames';

type TableRowExpandedProps = {
  // Any is used purposefully here - because this data can really be anything
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data: any;
  readonly isRowExpanded: boolean;
  readonly noResultsLabel: string;
};

export const ExpandableTableRowExpanded = ({
  data,
  isRowExpanded,
  noResultsLabel,
}: TableRowExpandedProps) => {
  const renderTableData = () => {
    if (data) {
      return (
        <tr>
          <td>
            <div className="row-data">{JSON.stringify(data, null, 2)}</div>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>{noResultsLabel}</td>
        </tr>
      );
    }
  };

  return (
    <tr
      className={classNames(
        'row',
        isRowExpanded ? 'row--collapsible-expanded' : 'row--collapsible-collapsed'
      )}
    >
      <td colSpan={5}>
        <table>
          <tbody>{renderTableData()}</tbody>
        </table>
      </td>
    </tr>
  );
};
