export const transformFilterValuesToSearchParameters = (filterState: {
  [option: string]: boolean;
}) => {
  const values = [];

  for (const [option, selected] of Object.entries(filterState)) {
    if (selected) {
      values.push(option);
    }
  }

  return values.length ? values.join(',') : '';
};
