import { Button } from '@module/mdrt-org/shared/components';
import { ButtonVariant } from '@shared/ui-components';

import styles from './search-suggestions.module.scss';

type SearchSuggestionsProps = {
  onSuggestionClick: (title: string) => void;
};

// TODO: get suggestions from BE
const suggestions = [
  { id: '1', title: 'Motivate clients through successful storytelling' },
  { id: '2', title: 'Top of the Table Q&A: Matthew T. Hoesly, CFP, ChFC' },
  { id: '3', title: 'Top of the Table Q&A: Qun Lin Amanda Goh' },
  { id: '4', title: 'Using coaching to unleash your true potential' },
  { id: '5', title: 'Incorporating the role of a paraplanner into your practice' },
  { id: '6', title: 'Motivating a client to take action' },
  { id: '7', title: 'Motivate this' },
  { id: '8', title: 'The 8 proven principles for perfection' },
  { id: '9', title: 'The international professional paraplanner: Unleash your new super power!' },
];

export const SearchSuggestions = ({ onSuggestionClick }: SearchSuggestionsProps) => {
  // eslint-disable-next-line no-constant-condition
  if (true || suggestions.length <= 0) return null;

  return (
    <div className={styles.wrapper}>
      <p
        aria-label={`Suggestions: ${suggestions.length}`}
        aria-live="polite"
        className={styles.title}
      >
        Suggestions
      </p>
      {suggestions.map(({ id, title }) => (
        <Button
          aria-label={title}
          className={styles.suggestion}
          key={id}
          onClick={() => onSuggestionClick(title)}
          variant={ButtonVariant.Plain}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};
