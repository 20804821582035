import classNames from 'classnames';

import { MdrtFullLogo, RenderHTML } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { type MembershipInvoicePage } from '../../data/get-membership-invoice-page';

import styles from './contact-section.module.scss';

type ContactSectionProps = {
  membershipInvoicePageContent: MembershipInvoicePage;
  membershipYear: number;
};

export const ContactSection = ({
  membershipInvoicePageContent,
  membershipYear,
}: ContactSectionProps) => {
  const headerTitleLabel = formatTranslationStringOnClient(
    membershipInvoicePageContent.headerTitleText,
    {
      membershipApplicationYear: membershipYear,
    }
  );

  return (
    <section className={classNames(styles.section)}>
      <p className={styles.section__title}>{headerTitleLabel}</p>

      <div className={styles['section__logo-wrapper']}>
        <MdrtFullLogo className={styles.section__logo} />
      </div>

      <div className={styles.section__contact}>
        <RenderHTML
          as="p"
          className={styles.section__address}
          html={membershipInvoicePageContent.headerAddressText}
        />

        <RenderHTML
          as="p"
          className={styles.section__phone}
          html={membershipInvoicePageContent.headerPhoneText}
        />
      </div>
    </section>
  );
};
