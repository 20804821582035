'use client';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { type SyncEventsTableContent } from '@module/mdrt-org/admin-dashboard/components/sync-events-table/get-sync-events-table-content';
import {
  deleteAllCustomerData,
  getInboundSyncEvents,
} from '@module/mdrt-org/admin-dashboard/utils';
import { Button, LoaderWrapper, Pagination } from '@shared/ui-components';
import {
  dateFormatter,
  formatTranslationStringOnClient,
  INITIAL_PAGE_NUMBER,
  MAX_ITEMS_PER_LIST,
} from '@shared/utils';

import { AdminDashboardDataTag } from '../../utils/data/constants/tags';
import { type SyncEvent } from '../../utils/data/types/sync-event';
import { ExpandableTableRow } from '../expandable-table-row';

import { SyncEventTableFilter } from './sync-event-table-filter';
import styles from './sync-events-table.module.scss';

export const SyncEventsTable = ({
  adminDashboardPath,
  cmsContent,
  personId,
}: {
  adminDashboardPath: string;
  cmsContent: SyncEventsTableContent;
  personId: number;
}) => {
  const [eventType, setEventType] = useState<SyncEvent | 'none'>();
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE_NUMBER);

  const router = useRouter();

  const {
    data: syncEventsData,
    error: getSyncEventsDataError,
    isFetching: isSyncEventsDataLoading,
  } = useQuery({
    queryFn: () =>
      getInboundSyncEvents({
        count: MAX_ITEMS_PER_LIST,
        eventType,
        page: currentPage,
        personId,
      }),
    queryKey: [AdminDashboardDataTag.GET_SYNC_DATA, eventType, currentPage, personId],
  });

  const { isFetching: isDeleteAllCustomerDataLoading, refetch: handleDeleteAllCustomerData } =
    useQuery({
      enabled: false,
      queryFn: () => deleteAllCustomerData({ personId }),
      queryKey: [AdminDashboardDataTag.DELETE_ALL_USER_DATA],
    });

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
  };

  const confirmationMessage = formatTranslationStringOnClient(
    cmsContent.syncEventsTable.syncEventsUserDataDeleteConfirmation,
    {
      addColon: true,
      personId,
    }
  );

  const handleDeleteAllCustomerDataClick = async () => {
    // eslint-disable-next-line no-alert
    const userConfirmed = window.confirm(confirmationMessage);
    if (userConfirmed) {
      await handleDeleteAllCustomerData();
      router.push(adminDashboardPath, { scroll: true });
    }
  };

  const isLoading = isSyncEventsDataLoading || isDeleteAllCustomerDataLoading;

  return (
    <LoaderWrapper error={getSyncEventsDataError} isLoading={isLoading}>
      <section className="table-section">
        <div className={styles['table-actions']}>
          <Button onClick={handleDeleteAllCustomerDataClick}>
            {cmsContent.syncEventsTable.syncEventsDeleteAllUserData}
          </Button>
          <SyncEventTableFilter
            cmsContent={cmsContent.syncEventsFilter}
            setEventType={setEventType}
          />
        </div>
        <div className="table-wrapper">
          <table className="table">
            <thead className="table__header">
              <tr>
                <td>{cmsContent.syncEventsTable.syncEventsSyncEvent}</td>
                <td>{cmsContent.syncEventsTable.syncEventsSyncOperation}</td>
                <td>{cmsContent.syncEventsTable.syncEventsDate}</td>
              </tr>
            </thead>
            <tbody className="table__body">
              {syncEventsData?.results?.map((item) => (
                <ExpandableTableRow
                  expandableData={item}
                  key={`${item.syncedAtUtc}-${item.syncOperation}`}
                  noResultsLabel={cmsContent.syncEventsTable.syncEventsNoResults}
                >
                  <td>{item.syncType}</td>
                  <td>{item.syncOperation}</td>
                  <td>{dateFormatter(item.syncedAtUtc)}</td>
                </ExpandableTableRow>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          initialPage={currentPage}
          onPageChange={handlePageChange}
          totalItemsCount={syncEventsData?.count}
        />
      </section>
    </LoaderWrapper>
  );
};
