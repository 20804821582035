import { type CustomerProfile } from '../types';

export const getProfileDisplayName = (customerProfileData: CustomerProfile | undefined | null) => {
  if (customerProfileData?.nickname) {
    return customerProfileData?.nickname;
  }

  if (customerProfileData?.firstName && customerProfileData?.firstName) {
    return `${customerProfileData?.firstName} ${customerProfileData?.lastName}`;
  }

  return customerProfileData?.lastName || null;
};
