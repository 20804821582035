'use client';

import classNames from 'classnames';

import { type DeadLettersTableContent } from '@module/mdrt-org/admin-dashboard/components/dead-letters-section/get-dead-letters-section-content';

import { type DeadLetterResponseData } from '../../utils/data/types/dead-letter';
import { ExpandableTableRow } from '../expandable-table-row';

import styles from './dead-letters-table.module.scss';

export const DeadLettersTable = ({
  cmsContent,
  tableData,
}: {
  cmsContent: DeadLettersTableContent;
  tableData: DeadLetterResponseData;
}) => {
  return (
    <div>
      <div className={styles['table-title']}>
        <span className={styles['table-title__bold']}> {cmsContent.deadLettersMessageName}</span>
        {tableData.messageName}
      </div>
      {/* TODO add date filter */}
      <div className={classNames('table-wrapper', styles['dead-letters-table-wrapper'])}>
        <table className={classNames('table', styles['dead-letters-table-wrapper__table'])}>
          <thead className="table__header">
            <tr>
              <td>{cmsContent.deadLettersMessageId}</td>
              <td>{cmsContent.deadLettersQueueName}</td>
              <td>{cmsContent.deadLettersEnqueuedTime}</td>
            </tr>
          </thead>
          <tbody className="table__body">
            {tableData.deadLetters?.map((deadLetter) => (
              <ExpandableTableRow
                expandableData={JSON.parse(deadLetter.messageBody)}
                key={deadLetter.enqueuedTime}
                noResultsLabel={cmsContent.deadLettersNoTableResults}
              >
                <td>{deadLetter.messageId}</td>
                <td>{deadLetter.queueName}</td>
                <td>{deadLetter.enqueuedTime}</td>
              </ExpandableTableRow>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
