'use client';

import { useEffect, useState } from 'react';

import { Button } from '@module/mdrt-org/shared/components';
import { useRouter } from '@module/mdrt-org/shared/i18n';
import { ArrowIcon, ButtonVariant } from '@shared/ui-components';

import styles from './detail-page-pwa-back-button.module.scss';

type DetailPagePwaBackButtonProps = {
  ariaLabel: string;
  label: string;
};

export const DetailPagePwaBackButton = ({ ariaLabel, label }: DetailPagePwaBackButtonProps) => {
  const router = useRouter();
  const [isPwa, setIsPwa] = useState(false);
  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    setIsPwa(
      // @ts-expect-error - expected
      window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
    );
    setHasHistory(window.history.length > 1);
  }, []);

  if (!isPwa || !hasHistory) return null;

  return (
    <Button
      aria-label={ariaLabel}
      className={styles.button}
      dataTestId="detail-pwa-back-button"
      onClick={() => router.back()}
      prefix={<ArrowIcon className={styles['button__icon']} />}
      variant={ButtonVariant.Tertiary}
    >
      {label}
    </Button>
  );
};
