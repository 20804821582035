import { type ChangeEvent } from 'react';

import { type Transform } from '@shared/ui-components';

export const allowOnlyNumbersTransform: Transform<HTMLInputElement> = {
  input: (value: number) => {
    const stringValue = value?.toString().slice(0, 4);

    return Number.isNaN(value) || value === 0 ? '' : stringValue;
  },
  output: (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.slice(0, 4);
    const output = Number.parseInt(inputValue, 10);

    return Number.isNaN(output) ? 0 : output;
  },
};
