export enum AdminDashboardDataTag {
  DEAD_LETTER_QUEUE = 'dead-letters-queue',
  DELETE_ALL_USER_DATA = 'delete-all-user-data',
  GET_CUSTOMER_PROFILE_DATA = 'get-customer-profile-data',
  GET_SYNC_DATA = 'get-sync-data',
  GET_USER_ABNORMALITIES = 'get-user-abnormalities',
  POST_COMMUNICATION_PREFERENCES_SYNC = 'post-communication-preferences-sync',
  POST_CUSTOMER_PROFILE_DATA_SYNC = 'post-customer-profile-data-sync',
  POST_EXCLUDED_FROM_LATE_FEE = 'post-excluded-from-late-fee',
  POST_MEMBERSHIP_APPLICATION_SYNC = 'post-membership-application-sync',
  POST_PRE_PAYMENT_SYNC = 'post-pre-payment-sync',
  POST_PRODUCTION_SYNC = 'post-production-sync',
  POST_RETRY_DEAD_LETTERS = 'post-retry-dead-letters',
}
