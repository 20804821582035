'use client';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { FieldController, SelectField } from '@module/mdrt-org/shared/components';
import { Chip, ChipTheme, FormInput, type SelectOption } from '@shared/ui-components';

import {
  MAX_AREA_CODE_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
} from '../../utils/constants/account-settings';
import { allowOnlyNumbersTransform } from '../../utils/helpers/allow-only-numbers';
import { type ContactInformationFormData } from '../../utils/types/form-data';

import { type AccountSettingsContactInformationContent } from './get-account-settings-contact-information-content';
import { usePhoneSectionData } from './use-phone-section-data';
import styles from './phone-section.module.scss';

const PARENT_CLASSNAME = 'phone-section';

export const PhoneSection = ({
  cmsContent,
}: {
  cmsContent: AccountSettingsContactInformationContent;
}) => {
  const { append, fields, remove } = useFieldArray({
    name: 'phoneNumbers',
  });

  const { watch } = useFormContext<ContactInformationFormData>();

  const { defaultPhoneNumber, phoneIsoCodeOptions, phoneTypeOptions } = usePhoneSectionData();

  const watchedPhoneNumbers = watch('phoneNumbers');

  const filterUnselectedPhoneTypeOptions = (item: SelectOption) =>
    !watchedPhoneNumbers.some((fieldItem) => item?.value === fieldItem.phoneTypeOption?.value);

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div className={styles[`${PARENT_CLASSNAME}__header`]}>
            <h2 className={styles[`${PARENT_CLASSNAME}__header-title`]}>
              {index === 0 ? cmsContent.primaryPhoneTitle : cmsContent.additionalPhoneTitle}
            </h2>
            {index > 0 && (
              <button
                className={styles[`${PARENT_CLASSNAME}__remove-button`]}
                onClick={() => remove(index)}
                type="button"
              >
                {cmsContent.removeLabel}
              </button>
            )}
          </div>
          <div className={styles[`${PARENT_CLASSNAME}__phone-type-input-container`]}>
            <SelectField
              label={cmsContent.phoneTypeLabel}
              name={`phoneNumbers.${index}.phoneTypeOption`}
              options={phoneTypeOptions.filter(filterUnselectedPhoneTypeOptions)}
              rules={{
                required: cmsContent.requiredLabel,
              }}
            />
          </div>
          <div className={styles[`${PARENT_CLASSNAME}`]}>
            <div className={styles[`${PARENT_CLASSNAME}__input-container`]}>
              <SelectField
                isSearchable
                label={cmsContent.ISOCodeLabel}
                name={`phoneNumbers.${index}.phoneIsoCodeOption`}
                options={phoneIsoCodeOptions}
                rules={{ required: cmsContent.requiredLabel }}
              />
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__input-container`]}>
              <FieldController
                label={cmsContent.areaCodeLabel}
                name={`phoneNumbers.${index}.phoneAreaCode`}
                transform={allowOnlyNumbersTransform}
              >
                <FormInput maxLength={MAX_AREA_CODE_LENGTH} />
              </FieldController>
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__input-container`]}>
              <FieldController
                label={cmsContent.phoneNumberLabel}
                name={`phoneNumbers.${index}.phoneNumber`}
                rules={{
                  required: cmsContent.requiredLabel,
                }}
              >
                <FormInput maxLength={MAX_PHONE_NUMBER_LENGTH} />
              </FieldController>
            </div>
          </div>
        </div>
      ))}
      {fields.length < 3 && (
        <Chip
          isSmall
          label={`+ ${cmsContent.additionalPhoneLabel}`}
          onClick={() => append({ ...defaultPhoneNumber, phoneTypeOption: null })}
          theme={ChipTheme.Light}
        />
      )}
    </>
  );
};
