export enum WizardStepName {
  GETTING_STARTED = 'Getting Started',
  MEMBERSHIP_LEVEL = 'Membership Level',
  MEMBERSHIP_TYPE = 'Membership Type',
  PRODUCTION = 'Production',
  REVIEW = 'Review',
  WELCOME = 'Welcome',
}

export enum WizardStepStatus {
  ACCESSIBLE = 'Accessible',
  DISABLED = 'Disabled',
}
