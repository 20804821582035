import { type NumericFormatProps, numericFormatter } from 'react-number-format';

import { CURRENCY, EMPTY_SYMBOL, MONETARY_FORMAT_PROPS } from '../../constants';

export const formatToMonetary = (
  value?: number | null,
  props?: NumericFormatProps,
  displayEmpty = true
) => {
  const defaultProps = {
    ...MONETARY_FORMAT_PROPS,
    ...props,
  };

  if (value || (value === 0 && !displayEmpty)) {
    return numericFormatter(
      `${value}`,
      value < 0 ? { ...defaultProps, prefix: `${CURRENCY} -` } : defaultProps
    );
  }

  return EMPTY_SYMBOL;
};
