export enum SelfAttestedOption {
  cotCommission = 'cotCommission',
  cotIncome = 'cotIncome',
  cotPremium = 'cotPremium',
  mdrtCommission = 'mdrtCommission',
  mdrtIncome = 'mdrtIncome',
  mdrtPremium = 'mdrtPremium',
  totCommission = 'totCommission',
  totIncome = 'totIncome',
  totPremium = 'totPremium',
}

export enum ProductionType {
  COMMISSION = 'Commission',
  INCOME = 'Income',
  PREMIUM = 'Premium',
}
