'use client';

import classNames from 'classnames';
import { type ReactNode, useState } from 'react';

import { Caret } from '@shared/ui-components';

import { ExpandableTableRowExpanded } from './expandable-table-row-expanded';

type TableRowProps = {
  children: ReactNode;
  // Any is used purposefully here - because this data can really be anything
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandableData: any;
  noResultsLabel: string;
};

export const ExpandableTableRow = ({ children, expandableData, noResultsLabel }: TableRowProps) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  return (
    <>
      <tr
        className={classNames(expandableData && 'row__clickable')}
        onClick={() => setIsRowExpanded(!isRowExpanded)}
      >
        {children}
        <td className="cell--right">
          <div
            className={classNames('cell__action-button', {
              'cell__action-button--active': isRowExpanded,
            })}
          >
            <Caret />
          </div>
        </td>
      </tr>
      <ExpandableTableRowExpanded
        data={expandableData}
        isRowExpanded={isRowExpanded}
        noResultsLabel={noResultsLabel}
      />
    </>
  );
};
