'use client';

import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import {
  SEARCH_CONTENT_TYPE_PARAM_KEY,
  SEARCH_PAGE_PARAM_KEY,
  SEARCH_PERSON_PARAM_KEY,
  SEARCH_QUERY_PARAM_KEY,
  SEARCH_SOURCE_PARAM_KEY,
  SEARCH_TOPIC_PARAM_KEY,
  SEARCH_YEAR_PARAM_KEY,
} from '@module/mdrt-org/shared/utils/constants';

import { getNormalizedSearchPageSearchParameters } from '../get-normalized-search-page-search-parameters';

export const useSearchPageSearchParameters = () => {
  const searchParameters = useSearchParams();

  return useMemo(
    () =>
      getNormalizedSearchPageSearchParameters({
        [SEARCH_CONTENT_TYPE_PARAM_KEY]:
          searchParameters.get(SEARCH_CONTENT_TYPE_PARAM_KEY) ?? undefined,
        [SEARCH_PAGE_PARAM_KEY]: searchParameters.get(SEARCH_PAGE_PARAM_KEY) ?? undefined,
        [SEARCH_PERSON_PARAM_KEY]: searchParameters.get(SEARCH_PERSON_PARAM_KEY) ?? undefined,
        [SEARCH_QUERY_PARAM_KEY]: searchParameters.get(SEARCH_QUERY_PARAM_KEY) ?? undefined,
        [SEARCH_SOURCE_PARAM_KEY]: searchParameters.get(SEARCH_SOURCE_PARAM_KEY) ?? undefined,
        [SEARCH_TOPIC_PARAM_KEY]: searchParameters.get(SEARCH_TOPIC_PARAM_KEY) ?? undefined,
        [SEARCH_YEAR_PARAM_KEY]: searchParameters.get(SEARCH_YEAR_PARAM_KEY) ?? undefined,
      }),
    [searchParameters]
  );
};
