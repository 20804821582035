import classnames from 'classnames';

import { Chip, ChipTheme } from '@shared/ui-components';

import { type SearchCmsContent } from '../../../../graphql/get-search-cms-content';

import styles from './filter-footer.module.scss';

type FilterFooterProps = {
  className?: string;
  formId: string;
  handleReset: () => void;
  searchCmsContent: SearchCmsContent;
};

export const FilterFooter = ({
  className,
  formId,
  handleReset,
  searchCmsContent,
}: FilterFooterProps) => (
  <footer className={classnames(styles['filter-footer'], className)}>
    <Chip label={searchCmsContent.generic.reset} onClick={handleReset} theme={ChipTheme.Light} />
    <Chip
      className={styles['filter-footer__apply-button']}
      form={formId}
      label={searchCmsContent?.searchPage?.FilterResultsLabel || 'Filter results'}
      theme={ChipTheme.Emphasis}
      type="submit"
    />
  </footer>
);
