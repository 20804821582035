'use client';

import { Button } from '@module/mdrt-org/shared/components/button';
import {
  EMPTY_SYMBOL,
  NOT_ENTERED,
} from '@module/mdrt-org/shared/utils/constants/string-constants';
import { AccountSettingsFormSection, MembershipLevel } from '@module/mdrt-org/shared/utils/enums';
import {
  formatDateYearMonthDay,
  formatToMonetary,
} from '@module/mdrt-org/shared/utils/helpers/formatters';
import { useGetYesNoLabel } from '@module/mdrt-org/shared/utils/helpers/strings/use-get-yes-no-label';
import {
  type PaymentProductName,
  PaymentProductType,
  type ReceiptDto,
} from '@module/mdrt-org/shared/utils/types';
import { ArrowIcon, ButtonVariant } from '@shared/ui-components';

import { type MembershipInvoicePage } from '../data/get-membership-invoice-page';

import { ContactSection } from './contact-section/contact-section';
import styles from './membership-invoice.module.scss';

export type MembershipInvoiceProps = {
  lastYearOfMembership: number;
  membershipInvoice: ReceiptDto;
  membershipInvoicePageContent: MembershipInvoicePage;
};

export const valueOrEmptySymbol = (value: string | number | null | undefined): string | number =>
  value || EMPTY_SYMBOL;

export const enumKeyByValue = <T extends Record<string, string>>(
  enumeration: T,
  enumValue: (typeof enumeration)[keyof typeof enumeration]
): keyof T => Object.keys(enumeration).find((key) => enumeration[key] === enumValue) as keyof T;

export const getMembershipLevelKeys = () => ({
  cotLevelKey: enumKeyByValue(MembershipLevel, MembershipLevel.COT),
  mdrtLevelKey: enumKeyByValue(MembershipLevel, MembershipLevel.MDRT),
  totLevelKey: enumKeyByValue(MembershipLevel, MembershipLevel.TOT),
});

export const MembershipInvoice = ({
  lastYearOfMembership,
  membershipInvoice,
  membershipInvoicePageContent,
}: MembershipInvoiceProps) => {
  const getYesNoLabel = useGetYesNoLabel();

  const totalAmount =
    membershipInvoice?.totalAmountPaid && membershipInvoice?.totalAmountPaid > 0
      ? membershipInvoice?.totalAmountPaid
      : 0;

  const levelsPaymentProducts =
    membershipInvoice?.products?.filter((product) => product.type === PaymentProductType.Level) ??
    [];

  const backDuesPaymentProduct =
    membershipInvoice?.products?.filter(
      (product) => product.type === PaymentProductType.BackDues
    ) ?? [];

  const lateFeesPaymentProduct =
    membershipInvoice?.products?.filter(
      (product) => product.type === PaymentProductType.LateFees
    ) ?? [];

  const getProductNameLabel = (productName: PaymentProductName) => {
    const { cotLevelKey, mdrtLevelKey, totLevelKey } = getMembershipLevelKeys();

    if (!membershipInvoicePageContent) {
      return '';
    }

    switch (productName) {
      case mdrtLevelKey:
        return membershipInvoicePageContent.MDRTLevelLabel;
      case cotLevelKey:
        return membershipInvoicePageContent.COTLevelLabel;
      case totLevelKey:
        return membershipInvoicePageContent.TOTLevelLabel;
      case 'BackDues':
        return membershipInvoicePageContent.backDuesLabel;
      default:
        return EMPTY_SYMBOL;
    }
  };

  return (
    <>
      <div className={styles.actions}>
        <Button
          isSmall
          prefix={<ArrowIcon className={styles['actions__button-icon']} />}
          to={`${membershipInvoicePageContent.pagePaths.accountSettingPagePath}#${AccountSettingsFormSection.Profile}`}
          variant={ButtonVariant.Tertiary}
        >
          {membershipInvoicePageContent.backButtonLabel}
        </Button>
        <Button onClick={() => window.print()}>
          {membershipInvoicePageContent.printButtonLabel}
        </Button>
      </div>

      <section className={styles.receipt} data-testid="receipt-section">
        <ContactSection
          membershipInvoicePageContent={membershipInvoicePageContent}
          membershipYear={lastYearOfMembership}
        />

        <div className={styles.receipt__header}>
          <h1 data-testid="receipt-header">{membershipInvoicePageContent.invoiceHeader}</h1>
        </div>

        <div className={styles.receipt__section} data-testid="member-details-section">
          <h3 className={styles['receipt__section-title']}>
            {membershipInvoicePageContent.memberDetailsHeader}
          </h3>
          <div className={styles['receipt__section-content']}>
            <p className={styles['receipt__section-item']} data-testid="member-name-row">
              {membershipInvoicePageContent.memberNameLabel}
              <strong>{membershipInvoice?.memberFullName || NOT_ENTERED}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="mdrt-id-row">
              {membershipInvoicePageContent.MDRTIdLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.mdrtId)}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="phone-number-row">
              {membershipInvoicePageContent.phoneNumberLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.phoneNumber)}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="company-name-row">
              {membershipInvoicePageContent.companyNameLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.companyName)}</strong>
            </p>
          </div>
        </div>

        <div className={styles.receipt__section} data-testid="application-details-section">
          <h3 className={styles['receipt__section-title']}>
            {membershipInvoicePageContent.applicationDetailsHeader}
          </h3>
          <div className={styles['receipt__section-content']}>
            <p className={styles['receipt__section-item']} data-testid="terms-and-conditions-row">
              {membershipInvoicePageContent.termsAndConditionsLabel}
              <strong className={styles['receipt__section-item-value--capitalized']}>
                {getYesNoLabel(membershipInvoice?.acceptedTermsAndConditions)}
              </strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="ethics-complaint-row">
              {membershipInvoicePageContent.ethicsComplaintLabel}
              <strong className={styles['receipt__section-item-value--capitalized']}>
                {getYesNoLabel(membershipInvoice?.hasEthicsComplaint)}
              </strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="disability-row">
              {membershipInvoicePageContent.disabilityRequestedLabel}
              <strong className={styles['receipt__section-item-value--capitalized']}>
                {getYesNoLabel(membershipInvoice?.disabilityRequested)}
              </strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="membership-type-row">
              {membershipInvoicePageContent.membershipTypeLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.membershipType)}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="attest-row">
              {membershipInvoicePageContent.attestToProduction}
              <strong className={styles['receipt__section-item-value--capitalized']}>
                {getYesNoLabel(membershipInvoice?.attestedToProduction)}
              </strong>
            </p>
          </div>
        </div>

        <div className={styles.receipt__section} data-testid="payment-details-section">
          <h3 className={styles['receipt__section-title']}>
            {membershipInvoicePageContent.paymentDetailsHeader}
          </h3>
          <div className={styles['receipt__section-content']}>
            <p className={styles['receipt__section-item']} data-testid="payment-date-row">
              {membershipInvoicePageContent.paymentDateLabel}
              <strong>{formatDateYearMonthDay(membershipInvoice?.paymentDate || undefined)}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="order-number-row">
              {membershipInvoicePageContent.orderNumberLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.orderNumber)}</strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="payment-method-row">
              {membershipInvoicePageContent.paymentMethodLabel}
              <strong>{valueOrEmptySymbol(membershipInvoice?.paymentMethod)}</strong>
            </p>
          </div>
        </div>

        <div className={styles.receipt__section} data-testid="price-breakdown-section">
          <h3 className={styles['receipt__section-title']}>
            {membershipInvoicePageContent.priceBreakdownHeader}
          </h3>
          <div className={styles['receipt__section-content']}>
            <p className={styles['receipt__section-item']} data-testid="company-prepayment-row">
              {membershipInvoicePageContent.companyPrePaymentLabel}
              <strong>
                {formatToMonetary(
                  membershipInvoice?.companyPrePayment && -membershipInvoice.companyPrePayment,
                  {},
                  false
                )}
              </strong>
            </p>
            <p className={styles['receipt__section-item']} data-testid="self-prepayment-row">
              {membershipInvoicePageContent.selfPrePaymentLabel}
              <strong>
                {formatToMonetary(
                  membershipInvoice?.selfPrePayment && -membershipInvoice.selfPrePayment,
                  {},
                  false
                )}
              </strong>
            </p>
            <hr />
            {levelsPaymentProducts?.length > 0 &&
              levelsPaymentProducts?.map((product) => (
                <p
                  className={styles['receipt__section-item']}
                  data-testid={`${product.productName}-price-row`}
                  key={product.productId}
                >
                  {getProductNameLabel(product.productName)}
                  <strong>{formatToMonetary(product?.amount ?? 0, {}, false)}</strong>
                </p>
              ))}
            {backDuesPaymentProduct?.length > 0 &&
              backDuesPaymentProduct?.map((product) => (
                <p
                  className={styles['receipt__section-item']}
                  data-testid={`${product.productName}-price-row`}
                  key={product.productId}
                >
                  {membershipInvoicePageContent.backDuesLabel}
                  <strong>{formatToMonetary(product?.amount ?? 0, {}, false)}</strong>
                </p>
              ))}
            {lateFeesPaymentProduct?.length > 0 &&
              lateFeesPaymentProduct?.map((product) => (
                <p
                  className={styles['receipt__section-item']}
                  data-testid={`${product.productName}-price-row`}
                  key={product.productId}
                >
                  {membershipInvoicePageContent.lateFeeLabel}
                  <strong>{formatToMonetary(product?.amount ?? 0, {}, false)}</strong>
                </p>
              ))}
            {Boolean(membershipInvoice?.additionalFees) && (
              <p className={styles['receipt__section-item']} data-testid="additional-fee-row">
                {membershipInvoicePageContent.additionalFilingFeesLabel}
                <strong>
                  {formatToMonetary(membershipInvoice?.additionalFees ?? 0, {}, false)}
                </strong>
              </p>
            )}
            <hr />
            <p className={styles['receipt__section-item']} data-testid="total-amount-row">
              <strong>{membershipInvoicePageContent.totalAmountPaidLabel}</strong>
              <strong>{formatToMonetary(totalAmount, {}, false)}</strong>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
