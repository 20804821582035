'use client';

import { type UseMutateFunction } from '@tanstack/react-query';
import { type Dispatch, type SetStateAction } from 'react';

import { type PatchAssessmentQuestions } from '@module/mdrt-org/shared/utils/data/patch-assessment-questions';
import { type AssessmentQuestionsTopic } from '@module/mdrt-org/shared/utils/enums/assessment-questions-topic';
import { type MutationError } from '@module/mdrt-org/shared/utils/types';
import { Slider } from '@shared/ui-components';
import {
  GTM_EVENT_NAME_ASSESSMENT_SCORE_CHANGE,
  sendGtmEvent,
  type ServerActionResponse,
} from '@shared/utils';

import { calculateRoundedAverage } from './helpers';
import styles from './self-assessment-form.module.scss';

type SelfAssessmentFormData = {
  assessmentTopics: Array<{
    questions: Array<{ question: string; questionId: string; score: number }>;
    topic: AssessmentQuestionsTopic;
    topicScore: number;
  }>;
};

type SelfAssessmentFormProps = {
  mutateAssessmentQuestions: UseMutateFunction<
    ServerActionResponse | Response | MutationError | null,
    Error,
    PatchAssessmentQuestions,
    unknown
  >;
  selfAssessmentFormData: SelfAssessmentFormData;
  setSelfAssessmentFormData: Dispatch<SetStateAction<SelfAssessmentFormData>>;
  topic: AssessmentQuestionsTopic;
};

export const SelfAssessmentForm = ({
  mutateAssessmentQuestions,
  selfAssessmentFormData,
  setSelfAssessmentFormData,
  topic,
}: SelfAssessmentFormProps) => {
  const currentTopicData = selfAssessmentFormData?.assessmentTopics?.find(
    (topicItem) => topicItem.topic === topic
  );

  const handleScoreChange = async (score: number, questionId: string) => {
    // TODO: Handle request stacing and navigating while loading issue

    if (currentTopicData === undefined) {
      return;
    }

    const updatedCurrentTopicQuestions = currentTopicData?.questions.map((topicItem) => {
      if (topicItem.questionId !== questionId) {
        return topicItem;
      }

      return { ...topicItem, score };
    });

    const questionToUpdate = currentTopicData.questions.find(
      (question) => question.questionId === questionId.toString()
    );

    if (!questionToUpdate || questionToUpdate.score === score) {
      return;
    }

    const scoresArray = updatedCurrentTopicQuestions?.map((item) => item.score);
    const averageScore = scoresArray ? calculateRoundedAverage(scoresArray) : 0;

    const updatedState = {
      ...selfAssessmentFormData,
      assessmentTopics: selfAssessmentFormData.assessmentTopics.map((item) => {
        if (item.topic !== topic) {
          return item;
        }

        return {
          ...item,
          questions: updatedCurrentTopicQuestions,
          topicScore: averageScore,
        };
      }),
    };

    setSelfAssessmentFormData(updatedState);

    const payload = {
      questions:
        updatedState?.assessmentTopics
          .find((item) => item.topic === topic)
          ?.questions.map((item) => ({
            questionId: item.questionId,
            score: item.score,
          })) ?? [],
      topic,
    };

    mutateAssessmentQuestions(payload);
  };

  return (
    <form className={styles['assessment-form']} data-testid="quiz-form">
      <ol className={styles['assessment-form__list']} data-testid="quiz-questions-list">
        {currentTopicData?.questions?.map((question) => {
          return (
            <li
              className={styles['assessment-form__list-item']}
              data-testid="quiz-questions-list-item"
              key={question.questionId}
            >
              <span aria-hidden="true" id={`quiz-question-${question.questionId.toString()}`}>
                {question.question}
              </span>
              <Slider
                ariaLabelledBy={`quiz-question-${question.questionId.toString()}`}
                defaultValue={question.score}
                name={`question-${question.questionId}`}
                onChangeHandler={(score) => {
                  if (score !== question.score) {
                    handleScoreChange(score, question.questionId.toString());
                    sendGtmEvent({
                      eventName: GTM_EVENT_NAME_ASSESSMENT_SCORE_CHANGE,
                      label: question.question,
                      new_value: score,
                      old_value: question.score,
                    });
                  }
                }}
              />
            </li>
          );
        })}
      </ol>
    </form>
  );
};
