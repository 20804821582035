import {
  type CommunicationPreferences,
  type CenterCommunicationTopics,
  CenterCommunicationPreferenceTopics,
} from '@module/mdrt-org/shared/utils/types';

export const mapCenterCommunicationTopics = (
  communicationPreferences: CommunicationPreferences
): CenterCommunicationTopics => {
  const keyToTopicMap: Record<
    keyof CenterCommunicationTopics,
    CenterCommunicationPreferenceTopics
  > = {
    businessAnnouncements: CenterCommunicationPreferenceTopics.BusinessAnnouncements,
    contentNewsletter: CenterCommunicationPreferenceTopics.ContentNewsletter,
    mdrtMeetings: CenterCommunicationPreferenceTopics.MdrtMeetings,
    memberBenefits: CenterCommunicationPreferenceTopics.MemberBenefits,
  };

  const isKey = (value: string): value is keyof typeof keyToTopicMap =>
    Object.keys(keyToTopicMap).includes(value);

  const keys = Object.keys(keyToTopicMap).filter(isKey);

  return Object.fromEntries(
    keys.map((currentValue: keyof CenterCommunicationTopics) => [
      currentValue,
      communicationPreferences.mdrtCenterPreferenceCenter?.emails?.find(
        (emailPermission) => emailPermission.topic === keyToTopicMap[currentValue]
      )?.optIn ?? false,
    ])
  ) as CenterCommunicationTopics;
};
