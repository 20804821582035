import { useGlobalCmsContentContext } from '../../../components/providers/global-cms-context';

import { getYesNoLabel } from './getYesNoLabel';

export const useGetYesNoLabel = () => {
  const { cmsContent } = useGlobalCmsContentContext();

  return (value: boolean) =>
    getYesNoLabel(value, {
      noLabel: cmsContent.noLabel,
      yesLabel: cmsContent.yesLabel,
    });
};
