import { useLocale } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { type LearnSearchResults } from '@module/mdrt-org/shared/utils/data/graphql';
import { useMappedIntlLocale } from '@module/mdrt-org/shared/utils/hooks';
import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

import { useSearchPageSearchParameters } from '../../utils/hooks/use-search-page-search-parameters';

import {
  type AdvancedFilterFormData,
  type AdvancedFilterOptions,
} from './types/advanced-filter-form-data';
import { getDefaultValueObjectFromFilterOptions } from './utils/get-default-value-object-from-filter-options';
import { getFilterOptionsWithDefaultValues } from './utils/get-filter-options-with-default-values';

type UseSearchAdvancedFilterProps = {
  facets: LearnSearchResults['facets'];
};

export const useSearchAdvancedFilter = ({ facets }: UseSearchAdvancedFilterProps) => {
  const formMethods = useForm<AdvancedFilterFormData>();
  const [filterOptions, setFilterOptions] = useState<AdvancedFilterOptions | null>(null);
  const searchParameters = useSearchPageSearchParameters();
  const locale = useMappedIntlLocale();

  useEffect(() => {
    const updatedFilterOptions = getFilterOptionsWithDefaultValues({
      facets,
      formState: formMethods.getValues(),
      locale,
      searchParameters,
    });

    setFilterOptions(updatedFilterOptions);
    formMethods.reset(getDefaultValueObjectFromFilterOptions(updatedFilterOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facets, locale, searchParameters]);

  return useMemo(
    () => ({
      filterOptions,
      formMethods,
    }),
    [filterOptions, formMethods]
  );
};
