import { type QuizQuestionPropertyValue } from '@module/mdrt-org/shared/utils/data/graphql/_generated/gql-generated';
import { type SelfAssessment, type AssessmentTopic } from '@module/mdrt-org/shared/utils/types';
import { isDefined } from '@shared/utils';

import { type PersonQuizPageContent } from '../quiz-page-content-wrapper/get-person-quiz-page-content';

export const getQuestionsWithScores = (
  questionsArray: QuizQuestionPropertyValue[],
  assessmentTopicData: AssessmentTopic
) => {
  return questionsArray?.map((item) => {
    if (!item.QuestionId) throw new Error('Question ID must be defined');
    if (!item.Question) throw new Error('Question must be defined');

    const questionData = assessmentTopicData?.questions.find(
      (question) => question.questionId === item.QuestionId
    );

    return {
      question: item.Question,
      questionId: item.QuestionId,
      score: questionData?.score ?? 0,
    };
  });
};

export const getTopicQuestionsDefaultData = (questionsArray: QuizQuestionPropertyValue[]) => {
  return questionsArray?.map((item) => {
    if (!item.QuestionId) throw new Error('Question ID must be defined');

    return {
      questionId: item.QuestionId,
      score: 0,
    };
  });
};

export const calculateRoundedAverage = (numbersArray: number[]): number => {
  const numbersSum = numbersArray.reduce(
    (accumulator, currentNumber) => accumulator + currentNumber,
    0
  );

  if (!numbersSum) return 0;

  return Math.round(numbersSum / numbersArray.length);
};

export const getInitialSelfAssessmentFormData = (
  initialSelfAssessmentData: SelfAssessment,
  content: PersonQuizPageContent
) => {
  return {
    assessmentTopics: initialSelfAssessmentData?.assessmentTopics?.map((topicItem) => {
      const questionsArray = (content?.[topicItem.topic]?.Questions || []).filter(isDefined);
      return {
        ...topicItem,
        questions: getQuestionsWithScores(questionsArray, topicItem),
      };
    }),
  };
};
