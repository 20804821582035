import {
  SEARCH_CONTENT_TYPE_PARAM_KEY,
  SEARCH_PAGE_PARAM_KEY,
  SEARCH_QUERY_PARAM_KEY,
  VALID_SEARCH_PARAMS,
} from '@module/mdrt-org/shared/utils/constants';
import { ContentType } from '@module/mdrt-org/shared/utils/data/constants';
import { EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA } from '@shared/utils';

import { type SearchPageSearchParameters } from '../types';

export const getValidSearchParameters = (searchParameters: URLSearchParams) => {
  const validSearchParameters: SearchPageSearchParameters = {};
  for (const [key, value] of searchParameters.entries()) {
    if (VALID_SEARCH_PARAMS.includes(key)) {
      validSearchParameters[key as keyof SearchPageSearchParameters] =
        key === SEARCH_CONTENT_TYPE_PARAM_KEY ? ContentType[Number(value)] : value;
    }
  }

  return validSearchParameters;
};

export const getGtmSearchResultsViewEventValues = (
  searchParameters: SearchPageSearchParameters
) => {
  const { p: pageNumber, ...searchParametersWithoutPageNumber } = searchParameters;
  const q_pageNumber = `${Number(pageNumber || 0) + 1}`;
  const remainingParamsCount = Object.keys(searchParametersWithoutPageNumber).length;

  if (remainingParamsCount === 0) {
    return {
      ...EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA,
      q_pageNumber,
    };
  } else if (remainingParamsCount === 1 && SEARCH_QUERY_PARAM_KEY in searchParameters) {
    return {
      ...EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA,
      search_term: searchParameters[SEARCH_QUERY_PARAM_KEY],
      q_pageNumber,
    };
  }

  const values: Record<string, string | undefined> = Object.fromEntries(
    VALID_SEARCH_PARAMS.filter((key) => key !== SEARCH_PAGE_PARAM_KEY).map((key) => [
      key === SEARCH_QUERY_PARAM_KEY ? 'search_term' : key,
      searchParameters[key as keyof SearchPageSearchParameters],
    ])
  );

  return {
    ...EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA,
    q_selectedTerms: JSON.stringify(values),
    q_pageNumber,
  };
};
