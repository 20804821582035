'use client';

import { useState } from 'react';
import { type Key } from 'react-aria';
import { type Selection } from 'react-aria-components';
import { useFieldArray } from 'react-hook-form';
import { useListData } from 'react-stately';

import {
  Button,
  ButtonVariant,
  Chip,
  ChipTheme,
  Dialog,
  ModalTrigger,
  TagsList,
} from '@shared/ui-components';

import { type SitePreferencesFormData } from '../../../utils/types/form-data';
import { type InterestsData } from '../site-preferences-server';

import styles from './interests-section.module.scss';

type InterestsSectionProps = {
  allTopics: InterestsData;
  cmsContent: {
    addMoreTopicsButtonLabel: string;
    cancelButtonLabel: string;
    description: string;
    interestModalSubTitle: string;
    interestModalTitle: string;
    interestsErrorMessage: string;
    saveButtonLabel: string;
    title: string;
  };
};

const INTERESTS_MODAL_TITLE_ID = 'interest-modal-title';

export const InterestsSection = ({ allTopics, cmsContent }: InterestsSectionProps) => {
  const { append, fields, remove } = useFieldArray<SitePreferencesFormData>({
    name: 'interests',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const fieldTopicIds = fields.map(({ topicId }) => topicId);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(fieldTopicIds));
  const {
    addMoreTopicsButtonLabel,
    cancelButtonLabel,
    description,
    interestModalSubTitle,
    interestModalTitle,
    interestsErrorMessage,
    saveButtonLabel,
    title,
  } = cmsContent;

  const selectedInterests = useListData({
    initialItems: fields.map(({ name, topicId }) => ({
      id: topicId,
      name,
    })),
  });
  const allInterests = useListData({
    initialItems: allTopics.map(({ name, topicId }) => ({
      id: topicId,
      name,
    })),
  });

  const handleSelectionChange = (newKeys: Selection) => {
    const selectedSize = newKeys instanceof Set ? newKeys.size : 0;

    setSelectedKeys(newKeys);

    if (selectedSize >= 3 && errorMessage) {
      setErrorMessage(undefined);
    }
  };

  const saveInterests = () => {
    const selectedTopics = Array.from(selectedKeys);

    if (selectedTopics.length < 3) {
      setErrorMessage(interestsErrorMessage);
      return;
    }

    const newTopics = selectedTopics
      .filter((key) => !fields.some(({ topicId }) => topicId === key))
      .map((key) => allInterests.getItem(key));

    const removedFieldIndexes = fields
      .map(({ topicId }, index) => (selectedTopics.includes(topicId) ? null : index))
      .filter((index): index is number => index !== null);

    if (removedFieldIndexes) {
      remove(removedFieldIndexes);

      for (const { id } of selectedInterests.items.filter(
        (item) => !selectedTopics.includes(item.id)
      )) {
        selectedInterests.remove(id);
      }
    }

    append(newTopics.map(({ id, name }) => ({ name, topicId: id })));
    selectedInterests.append(...newTopics);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    if (errorMessage) setErrorMessage(undefined);

    setSelectedKeys(new Set(fieldTopicIds));
    setIsOpen(false);
  };

  return (
    <div>
      <TagsList
        additionalContentSlot={
          <Chip
            label={addMoreTopicsButtonLabel}
            onClick={() => setIsOpen(true)}
            theme={ChipTheme.Emphasis}
          />
        }
        description={description}
        items={selectedInterests.items}
        label={title}
      />
      <ModalTrigger isOpen={isOpen}>
        {() => (
          <Dialog
            footer={
              <div className={styles['interests-modal-controls']}>
                <Button onClick={cancelSelection} variant={ButtonVariant.Secondary}>
                  {cancelButtonLabel}
                </Button>
                <Button onClick={saveInterests}>{saveButtonLabel}</Button>
              </div>
            }
            footerClassName={styles['interests-modal-footer']}
            hasCloseButton={false}
            headerClassName={styles['interests-modal-header']}
            subtitle={interestModalSubTitle}
            title={interestModalTitle}
            titleId={INTERESTS_MODAL_TITLE_ID}
          >
            <div className={styles['interests-modal-content']}>
              <TagsList
                aria-labelledby={INTERESTS_MODAL_TITLE_ID}
                centerContent
                errorMessage={errorMessage}
                items={allInterests.items}
                onSelectionChange={handleSelectionChange}
                selectedKeys={selectedKeys}
                selectionMode="multiple"
              />
            </div>
          </Dialog>
        )}
      </ModalTrigger>
    </div>
  );
};
