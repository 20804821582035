'use client';

import { Button } from '@module/mdrt-org/shared/components';
import { loginHandler } from '@module/mdrt-org/shared/utils/helpers';
import { AuthProviderName } from '@shared/misc';
import { ButtonVariant } from '@shared/ui-components';

import styles from './login-banner.module.scss';

type LoginBannerCmsContentProps = {
  createAccountLabel: string;
  loginAlertText: string;
  loginPrompt: string;
};
type LoginBannerProps = {
  cmsContent: LoginBannerCmsContentProps;
  locale: string;
};

export const LoginBanner = ({ cmsContent, locale }: LoginBannerProps) => {
  return (
    <div aria-label={cmsContent.loginAlertText} className={styles.banner}>
      <div className={styles['banner__message']}>{cmsContent.loginAlertText}</div>
      <div className={styles['banner__buttons']}>
        <Button
          className={styles['banner__button']}
          onClick={() => loginHandler({ authorizationParameters: { ui_locales: locale } })}
          variant={ButtonVariant.Secondary}
        >
          {cmsContent.loginPrompt}
        </Button>
        <Button
          className={styles['banner__button']}
          onClick={() =>
            loginHandler({
              authorizationParameters: { ui_locales: locale },
              provider: AuthProviderName.CreateAccount,
            })
          }
        >
          {cmsContent.createAccountLabel}
        </Button>
      </div>
    </div>
  );
};
