'use client';

import { useMutationState } from '@tanstack/react-query';
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useGlobalLoaderContext } from '@module/mdrt-org/shared/components';
import { getCountryStates } from '@module/mdrt-org/shared/utils/data';
import { OrgSharedDataTag } from '@module/mdrt-org/shared/utils/data/constants';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { useQueryHandler } from '@module/mdrt-org/shared/utils/http-services';
import {
  type CenterApplicationDefinition,
  type CommunicationPreferences,
  type CustomerProfile,
  type State,
  AddressType,
} from '@module/mdrt-org/shared/utils/types';
import { type Country } from '@module/mdrt-org/shared/utils/types/countries';
import { type SelectOption } from '@shared/ui-components';

import {
  getCurrentPreferenceCenter,
  mapCenterCommunicationTopics,
  mapCommunicationTopics,
} from '../components/site-preferences-form/utils';
import {
  type CommunicationPreferencesPartial,
  type ContactInformationLocalAddressPartial,
  type ContactInformationMailingAddressPartial,
} from '../utils/types/form-data';

type AddressesContextData = {
  localAddress: ContactInformationLocalAddressPartial;
  mailingAddress: ContactInformationMailingAddressPartial;
};

type AccountSettingsPageContextValue = {
  addressesContextData: AddressesContextData;
  communicationPreferencesContextData: CommunicationPreferencesPartial;
  countriesData: Country[];
  customerProfileContextData: CustomerProfile;
  localAddressStateOptions: Array<SelectOption<number>>;
  mailingAddressStateOptions: Array<SelectOption<number>>;
  profilePictureContextData?: Nullable<string>;
  resetCommunicationPreferencesContextData: () => void;
  resetCustomerProfileContextData: () => void;
  resetProfilePictureContextData: () => void;
  setAddressesContextData: Dispatch<SetStateAction<AddressesContextData>>;
  setCommunicationPreferencesContextData: Dispatch<SetStateAction<CommunicationPreferencesPartial>>;
  setCustomerProfileContextData: Dispatch<SetStateAction<CustomerProfile>>;
  setProfilePictureContextData: Dispatch<Nullable<string | undefined>>;
};

const AccountSettingsPageContext = createContext<AccountSettingsPageContextValue>(
  {} as AccountSettingsPageContextValue
);

export const AccountSettingsPageContextProvider = ({
  centerApplicationDefinition,
  children,
  countriesData,
  initialCommunicationPreferencesData,
  initialCustomerProfileData,
  initialMailingAddressCountryStatesData,
  initialProfilePicture,
}: PropsWithChildren<{
  centerApplicationDefinition: CenterApplicationDefinition;
  countriesData: Country[];
  initialCommunicationPreferencesData: CommunicationPreferences;
  initialCustomerProfileData: CustomerProfile;
  initialMailingAddressCountryStatesData: State[];
  initialProfilePicture?: Nullable<string>;
}>) => {
  const { setIsNonBlockingLoaderVisible } = useGlobalLoaderContext();

  const mailingAddress = initialCustomerProfileData?.address?.find(
    (address) => address.type === AddressType.BUSINESS_ADDRESS
  );

  const localAddress = initialCustomerProfileData?.address?.find(
    (address) => address.type === AddressType.PO_BOX_ADDRESS
  );

  const [customerProfileContextData, setCustomerProfileContextData] = useState(
    initialCustomerProfileData
  );

  const [addressesContextData, setAddressesContextData] = useState<AddressesContextData>({
    localAddress: {
      localAddressAdded: localAddress !== undefined,
      localAddressCityOrTown: localAddress?.city ?? '',
      localAddressCountry: localAddress?.countryId,
      localAddressLineOne: localAddress?.line1 ?? '',
      localAddressLineThree: localAddress?.line3 ?? '',
      localAddressLineTwo: localAddress?.line2 ?? '',
      localAddressStateOrProvinceId: localAddress?.stateOrProvinceId,
      localAddressZipCode: localAddress?.postalCode ?? '',
    },
    mailingAddress: {
      addressCityOrTown: mailingAddress?.city ?? '',
      addressLineOne: mailingAddress?.line1 ?? '',
      addressLineThree: mailingAddress?.line3 ?? '',
      addressLineTwo: mailingAddress?.line2 ?? '',
      addressStateOrProvinceId: mailingAddress?.stateOrProvinceId ?? null,
      addressZipCode: mailingAddress?.postalCode ?? '',
    },
  });

  const [profilePictureContextData, setProfilePictureContextData] =
    useState<Nullable<string | undefined>>(initialProfilePicture);

  const userCommunicationPreferenceCenter = getCurrentPreferenceCenter(
    customerProfileContextData,
    centerApplicationDefinition
  );

  const convertedInitialCommunicationPreferencesData: CommunicationPreferencesPartial = {
    centerCommunicationTopics: mapCenterCommunicationTopics(initialCommunicationPreferencesData),
    communicationCenter: userCommunicationPreferenceCenter,
    communicationTopics: mapCommunicationTopics(initialCommunicationPreferencesData),
    contactPermission: {
      smsPermission: initialCommunicationPreferencesData.sms?.optIn ?? false,
    },
  };

  const [communicationPreferencesContextData, setCommunicationPreferencesContextData] = useState(
    convertedInitialCommunicationPreferencesData
  );

  const mutationState = useMutationState({
    filters: {
      mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    },
  });
  const isMutationPending = mutationState.some((mutation) => mutation.status === 'pending');

  const resetCustomerProfileContextData = () =>
    setCustomerProfileContextData(initialCustomerProfileData);
  const resetProfilePictureContextData = () => setProfilePictureContextData(initialProfilePicture);
  const resetCommunicationPreferencesContextData = () =>
    setCommunicationPreferencesContextData(convertedInitialCommunicationPreferencesData);

  const { data: mailingAddressCountryStatesData } = useQueryHandler({
    enabled: customerProfileContextData.countryId !== initialCustomerProfileData.countryId,
    initialData: initialMailingAddressCountryStatesData,
    queryFn: async () =>
      await getCountryStates({ countryId: customerProfileContextData.countryId }),
    queryKey: [
      OrgSharedDataTag.GET_COUNTRY_STATES,
      `${OrgSharedDataTag.GET_COUNTRY_STATES}_${customerProfileContextData.countryId}`,
    ],
  });

  const mailingAddressStateOptions =
    mailingAddressCountryStatesData?.map((state) => ({
      label: state.stateName,
      value: state.id,
    })) ?? [];

  const localAddressStateOptions =
    mailingAddressCountryStatesData?.map((state) => ({
      label: state.LocalLanguageStateProvince_mdrt ?? state.stateName,
      value: state.id,
    })) ?? [];

  const value: AccountSettingsPageContextValue = useMemo(
    () => ({
      addressesContextData,
      communicationPreferencesContextData,
      countriesData,
      customerProfileContextData,
      localAddressStateOptions,
      mailingAddressStateOptions,
      profilePictureContextData,
      resetCommunicationPreferencesContextData,
      resetCustomerProfileContextData,
      resetProfilePictureContextData,
      setAddressesContextData,
      setCommunicationPreferencesContextData,
      setCustomerProfileContextData,
      setProfilePictureContextData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addressesContextData,
      communicationPreferencesContextData,
      countriesData,
      customerProfileContextData,
      localAddressStateOptions,
      mailingAddressStateOptions,
      profilePictureContextData,
    ]
  );

  useEffect(() => {
    setCustomerProfileContextData(initialCustomerProfileData);
  }, [initialCustomerProfileData]);

  useEffect(() => {
    setIsNonBlockingLoaderVisible(isMutationPending);
  }, [isMutationPending, setIsNonBlockingLoaderVisible]);

  return (
    <AccountSettingsPageContext.Provider value={value}>
      {children}
    </AccountSettingsPageContext.Provider>
  );
};

export const useAccountSettingsPageContext = () => {
  const value = useContext(AccountSettingsPageContext);

  if (!value) {
    throw new Error(
      `${useAccountSettingsPageContext.name} must be used inside of ${AccountSettingsPageContextProvider.name}`
    );
  }

  return value;
};
