import { SUPPORTED_RESOURCE_CONTENT_TYPES } from '@module/mdrt-org/shared/utils/data/constants';

export const getValidContentType = (candidateType: string | null | undefined): number | null => {
  const parsedCandidateType = Number(candidateType);

  if (parsedCandidateType === 0) {
    return null;
  }

  if (SUPPORTED_RESOURCE_CONTENT_TYPES.includes(parsedCandidateType)) {
    return parsedCandidateType;
  }

  return null;
};
