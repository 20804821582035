'use client';

import { useForm } from 'react-hook-form';

import { FieldController, SelectField, useUserContext } from '@module/mdrt-org/shared/components';
import { deleteProfilePicture } from '@module/mdrt-org/shared/utils/data/delete-profile-picture';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { getProfileDisplayName } from '@module/mdrt-org/shared/utils/helpers/get-profile-display-name';
import { useConfirmNavigation, useMutationHandler } from '@module/mdrt-org/shared/utils/hooks';
import {
  Button,
  FormInput,
  FormTextarea,
  FormWrapper,
  type SelectOption,
} from '@shared/ui-components';
import {
  ButtonType,
  formBaseConfig,
  PICTURE_UPLOAD_SERVER_ERROR_KEY,
  type ServerActionResponse,
} from '@shared/utils';

import { saveAccountSettingsProfileStepAction } from '../../actions/save-account-settings-profile-step-action';
import { useAccountSettingsPageContext } from '../../providers';
import { MAX_BIO_LENGTH } from '../../utils/constants/account-settings';
import { allowedCharactersTransform, useAddAccountSettingsErrorToast } from '../../utils/helpers';
import { type ProfileFormData } from '../../utils/types';
import { ProfilePhotoUpload } from '../profile-photo-upload';

import { type AccountSettingsProfileContent } from './get-account-settings-profile-content';
import { MembershipPrintouts } from './membership-printouts';
import styles from './profile-form.module.scss';

type ProfileFormProps = {
  cmsContent: AccountSettingsProfileContent;
  isCurrentSeasonMember: boolean;
  isMember: boolean;
  membershipCertificateEndpointUrl: string;
  tabUrl: string;
};

const transformInputLineBreaks = (inputString: string | null) =>
  inputString && inputString.replaceAll('\r\n', '\n');

export const ProfileForm = ({
  cmsContent,
  isCurrentSeasonMember,
  isMember,
  membershipCertificateEndpointUrl,
  tabUrl,
}: ProfileFormProps) => {
  const {
    countriesData,
    customerProfileContextData,
    profilePictureContextData,
    resetCustomerProfileContextData,
    resetProfilePictureContextData,
    setCustomerProfileContextData,
    setProfilePictureContextData,
  } = useAccountSettingsPageContext();
  const { personId } = useUserContext();
  const addErrorToast = useAddAccountSettingsErrorToast();

  const profileDisplayName = getProfileDisplayName(customerProfileContextData);
  const defaultCountry = countriesData.find(
    (country) => country.countryId === customerProfileContextData.countryId
  );

  const formMethods = useForm<ProfileFormData>({
    ...formBaseConfig,
    defaultValues: {
      bio: transformInputLineBreaks(customerProfileContextData.bio) || '',
      country: defaultCountry
        ? {
            label: defaultCountry.name,
            value: defaultCountry.countryId,
          }
        : undefined,
      displayName: profileDisplayName || '',
      file: null,
    },
    resetOptions: {
      keepValues: true,
    },
  });

  const {
    formState: { isDirty },
    reset,
  } = formMethods;

  const countriesOptions: SelectOption[] = countriesData?.map((country) => ({
    label: country.name,
    value: `${country.countryId}`,
  }));

  const onFailure = (result: ServerActionResponse) => {
    if (
      result &&
      'messageTranslationKey' in result &&
      result.messageTranslationKey === PICTURE_UPLOAD_SERVER_ERROR_KEY
    ) {
      formMethods.setError('file', {
        message:
          result.messageTranslationKey === PICTURE_UPLOAD_SERVER_ERROR_KEY
            ? cmsContent.profilePicture.fileServerError
            : result.message,
        type: PICTURE_UPLOAD_SERVER_ERROR_KEY,
      });
    } else if (result.status !== 200 && 'message' in result) {
      resetCustomerProfileContextData();
      resetProfilePictureContextData();
      addErrorToast();
    }
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: saveAccountSettingsProfileStepAction,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const handleSubmit = async (data: ProfileFormData) => {
    const formData = new FormData();

    setCustomerProfileContextData({
      ...customerProfileContextData,
      bio: data.bio || '',
      countryId: Number(data.country?.value) || null,
    });

    if (data.file?.[0] && data.file[0] instanceof File) {
      setProfilePictureContextData(URL.createObjectURL(data.file[0]));
    }

    formData.append('accountSettingStep', AccountSettingsFormStep.Profile);
    formData.append("profileData['bio']", data.bio || '');
    formData.append("profileData['countryId']", data.country ? data.country.value.toString() : '');

    if (data.file?.[0]) {
      formData.append('file', data.file[0]);
    }

    mutate({
      formData,
      personId,
    });
  };

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <FormWrapper
      className={styles['profile-form']}
      dataTestId="profile-form"
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <h1 className={styles['profile-form__heading']}>{cmsContent.tabTitle}</h1>
      <ProfilePhotoUpload
        cmsContent={cmsContent.profilePicture}
        name="file"
        onPhotoDelete={async () => {
          setProfilePictureContextData(null);
          return await deleteProfilePicture({ personId });
        }}
        profilePicture={profilePictureContextData}
      />
      <FieldController<ProfileFormData> label={cmsContent.displayName} name="displayName">
        <FormInput disabled />
      </FieldController>
      <SelectField
        id="country"
        isSearchable
        label={cmsContent.country}
        name="country"
        options={countriesOptions}
        rules={{ required: cmsContent.requiredLabel }}
      />
      <FieldController<ProfileFormData>
        label={cmsContent.bio}
        name="bio"
        transform={allowedCharactersTransform}
      >
        <FormTextarea maxLength={MAX_BIO_LENGTH} />
      </FieldController>
      <MembershipPrintouts
        cmsContent={cmsContent}
        customerProfile={customerProfileContextData}
        isCurrentSeasonMember={isCurrentSeasonMember}
        isMember={isMember}
        membershipCertificateEndpointUrl={membershipCertificateEndpointUrl}
      />
      <div className={styles['profile-form__footer']}>
        <Button disabled={!isDirty || isMutationPending} type={ButtonType.SUBMIT}>
          {cmsContent.saveChanges}
        </Button>
      </div>
    </FormWrapper>
  );
};
