'use client';

import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FieldController, useUserContext } from '@module/mdrt-org/shared/components';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { useConfirmNavigation, useMutationHandler } from '@module/mdrt-org/shared/utils/hooks';
import { type PhoneType } from '@module/mdrt-org/shared/utils/types';
import { Button, FormInput, FormWrapper, type SelectOption } from '@shared/ui-components';
import { ButtonType, formBaseConfig, isObjectEmpty } from '@shared/utils';

import { useAccountSettingsPageContext } from '../../providers';
import { MAX_EMAIL_LENGTH, MAX_WECHAT_ID_LENGTH } from '../../utils/constants/account-settings';
import { useAddAccountSettingsErrorToast } from '../../utils/helpers/add-account-settings-error-toast';
import {
  type ContactInformationDTO,
  type ContactInformationFormData,
  type ContactInformationPhoneNumberFormDataPartial,
} from '../../utils/types/form-data';

import { type AccountSettingsContactInformationContent } from './get-account-settings-contact-information-content';
import { LocalAddressSection } from './local-address-section';
import { MailingAddressSection } from './mailing-address-section';
import { PhoneSection } from './phone-section';
import { usePhoneSectionData } from './use-phone-section-data';
import styles from './contact-information-form.module.scss';

const PARENT_CLASSNAME = 'contact-information-form';

enum EmailValidationKey {
  EmailAlreadyUsed = 'EmailAlreadyUsed',
  EmailInvalid = 'EmailInvalid',
  SecondEmailAlreadyUsed = 'SecondEmailAlreadyUsed',
  SecondEmailInvalid = 'SecondEmailInvalid',
}

const getOptionByValue = <T,>(options: Array<SelectOption<T>>, value: T | null | undefined) =>
  options.find((option) => option?.value === value ?? null) ?? null;

const getSelectedPhoneType = (phoneNumbers: ContactInformationPhoneNumberFormDataPartial[]) => {
  const firstPhone = phoneNumbers?.length > 0 ? phoneNumbers[0] : null;
  return (
    firstPhone?.phoneTypeOption?.value ? firstPhone.phoneTypeOption.value.toString() : ''
  ) as PhoneType;
};

export const ContactInformationForm = ({
  cmsContent,
  tabUrl,
}: {
  cmsContent: AccountSettingsContactInformationContent;
  tabUrl: string;
}) => {
  const {
    addressesContextData,
    countriesData,
    customerProfileContextData,
    localAddressStateOptions,
    mailingAddressStateOptions,
    resetCustomerProfileContextData,
    setAddressesContextData,
    setCustomerProfileContextData,
  } = useAccountSettingsPageContext();
  const { personId } = useUserContext();
  const addErrorToast = useAddAccountSettingsErrorToast();

  const { initialPhoneNumbers } = usePhoneSectionData();

  const countryOptions: Array<SelectOption<number>> = countriesData?.map((country) => ({
    label: country.name,
    value: country.countryId,
  }));

  const localAddressCountryOptions = countriesData.map((country) => ({
    label: country.localLanguageCountry ?? country.name,
    value: country.countryId,
  }));

  const customerProfileCountryId = customerProfileContextData.countryId;

  const localAddressStateOrProvinceId = getOptionByValue(
    localAddressStateOptions,
    addressesContextData.localAddress.localAddressStateOrProvinceId
  );

  const addressStateOrProvinceId = getOptionByValue(
    mailingAddressStateOptions,
    addressesContextData.mailingAddress.addressStateOrProvinceId
  );

  const defaultFormValues = useMemo(() => {
    return {
      addressCityOrTown: addressesContextData.mailingAddress.addressCityOrTown,
      addressLineOne: addressesContextData.mailingAddress.addressLineOne,
      addressLineThree: addressesContextData.mailingAddress.addressLineThree,
      addressLineTwo: addressesContextData.mailingAddress.addressLineTwo,
      addressStateOrProvinceId,
      addressZipCode: addressesContextData.mailingAddress.addressZipCode,
      countryId: getOptionByValue<number>(countryOptions, customerProfileCountryId),
      email: customerProfileContextData.emailAddress ?? '',
      localAddressAdded: addressesContextData.localAddress.localAddressAdded,
      localAddressCityOrTown: addressesContextData.localAddress.localAddressCityOrTown ?? '',
      localAddressCountry: getOptionByValue<number>(
        localAddressCountryOptions,
        customerProfileCountryId
      ),
      localAddressLineOne: addressesContextData.localAddress.localAddressLineOne ?? '',
      localAddressLineThree: addressesContextData.localAddress.localAddressLineThree ?? '',
      localAddressLineTwo: addressesContextData.localAddress.localAddressLineTwo ?? '',
      localAddressStateOrProvinceId,
      localAddressZipCode: addressesContextData.localAddress.localAddressZipCode ?? '',
      phoneNumbers: [...initialPhoneNumbers],
      secondEmail: customerProfileContextData.secondEmailAddress ?? '',
      selectedPhoneType: customerProfileContextData.selectedPhoneType,
      weChatId: customerProfileContextData.weChatId ?? '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAddressStateOptions, mailingAddressStateOptions]);

  const formMethods = useForm<ContactInformationFormData>({
    ...formBaseConfig,
    defaultValues: defaultFormValues,
  });

  const {
    formState: { dirtyFields, isDirty },
    reset,
    setValue,
  } = formMethods;

  // useEffect is necessary to re-render component when updated localAddressStateOptions is returned from accountSettingsPageContext
  useEffect(() => {
    setValue('addressStateOrProvinceId', addressStateOrProvinceId);
    setValue('localAddressStateOrProvinceId', localAddressStateOrProvinceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues]);

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<ContactInformationDTO>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure: (result) => {
      if (result.status !== 200 && 'message' in result) {
        resetCustomerProfileContextData();
        switch (result.messageTranslationKey) {
          case EmailValidationKey.EmailInvalid:
            formMethods.setError('email', { message: cmsContent.invalidEmailValidationMessage });
            return;
          case EmailValidationKey.EmailAlreadyUsed:
            formMethods.setError('email', { message: cmsContent.uniqueEmailValidationMessage });
            return;
          case EmailValidationKey.SecondEmailInvalid:
            formMethods.setError('secondEmail', {
              message: cmsContent.invalidEmailValidationMessage,
            });
            return;
          case EmailValidationKey.SecondEmailAlreadyUsed:
            formMethods.setError('secondEmail', {
              message: cmsContent.uniqueEmailValidationMessage,
            });
        }

        addErrorToast();
      }
    },
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const handleSubmit = (data: ContactInformationFormData) => {
    const formData = {
      countryId: customerProfileCountryId,
      email: data.email,
      phoneNumbers: data.phoneNumbers.map((item) => ({
        areaCode: item.phoneAreaCode || null,
        countryCode: item.phoneIsoCodeOption?.value ?? '',
        phoneNumber: item.phoneNumber,
        phoneType: item.phoneTypeOption?.value ?? '',
      })),
      secondEmail: data.secondEmail,
      selectedPhoneType: getSelectedPhoneType(data.phoneNumbers),
      weChatId: data.weChatId,
    };

    const localAddressFormData = {
      localAddressAdded: data.localAddressAdded,
      ...(data.localAddressAdded && {
        localAddressCityOrTown: data.localAddressCityOrTown,
        localAddressCountry: data.localAddressCountry?.value ?? undefined,
        localAddressLineOne: data.localAddressLineOne ?? undefined,
        localAddressLineThree: data.localAddressLineThree ?? undefined,
        localAddressLineTwo: data.localAddressLineTwo ?? undefined,
        localAddressStateOrProvinceId: data.localAddressStateOrProvinceId?.value ?? undefined,
        localAddressZipCode: data.localAddressZipCode ?? undefined,
      }),
    };
    const mailingAddressFormData = {
      addressCityOrTown: data.addressCityOrTown,
      addressLineOne: data.addressLineOne,
      addressLineThree: data.addressLineThree,
      addressLineTwo: data.addressLineTwo,
      addressStateOrProvinceId: data.addressStateOrProvinceId?.value || null,
      addressZipCode: data.addressZipCode,
    };

    setAddressesContextData({
      localAddress: localAddressFormData,
      mailingAddress: mailingAddressFormData,
    });

    setCustomerProfileContextData({
      ...customerProfileContextData,
      ...formData,
      emailAddress: data.email,
      secondEmailAddress: data.secondEmail,
    });

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.ContactInformation,
        contactData: {
          ...localAddressFormData,
          ...mailingAddressFormData,
          ...formData,
        },
      },
      personId,
    });
  };

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <FormWrapper
      className={styles[PARENT_CLASSNAME]}
      dataTestId="contact-information-form"
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <h1 className={styles[`${PARENT_CLASSNAME}__heading`]}>{cmsContent.titleLabel}</h1>
      <FieldController<ContactInformationFormData>
        label={cmsContent.emailLabel}
        name="email"
        rules={{
          required: cmsContent.requiredLabel,
          validate: {
            isDifferentFromSecondaryEmail: (value, formValues) =>
              value && formValues.secondEmail && value === formValues.secondEmail
                ? cmsContent.uniqueEmailValidationMessage
                : true,
          },
        }}
      >
        <FormInput maxLength={MAX_EMAIL_LENGTH} type="email" />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.secondEmailLabel}
        name="secondEmail"
        rules={{
          validate: {
            isDifferentFromPrimaryEmail: (value, formValues) =>
              value && formValues.email && value === formValues.email
                ? cmsContent.uniqueEmailValidationMessage
                : true,
          },
        }}
      >
        <FormInput maxLength={MAX_EMAIL_LENGTH} type="email" />
      </FieldController>
      <PhoneSection cmsContent={cmsContent} />
      <FieldController<ContactInformationFormData> label={cmsContent.weChatIDLabel} name="weChatId">
        <FormInput maxLength={MAX_WECHAT_ID_LENGTH} />
      </FieldController>
      <hr className={styles[`${PARENT_CLASSNAME}__separator`]} />
      <MailingAddressSection
        cmsContent={cmsContent}
        countryOptions={countryOptions}
        stateOptions={mailingAddressStateOptions}
      />
      <hr className={styles[`${PARENT_CLASSNAME}__separator`]} />
      <LocalAddressSection
        cmsContent={cmsContent}
        countryOptions={localAddressCountryOptions}
        customerProfileCountryId={customerProfileCountryId}
      />
      <div className={styles[`${PARENT_CLASSNAME}__footer`]}>
        <Button
          disabled={!isDirty || isObjectEmpty(dirtyFields) || isMutationPending}
          type={ButtonType.SUBMIT}
        >
          {cmsContent.saveChangesButtonLabel}
        </Button>
      </div>
    </FormWrapper>
  );
};
