import classnames from 'classnames';

import { Button, CloseIcon } from '@shared/ui-components';

import { type SearchCmsContent } from '../../../../graphql/get-search-cms-content';

import styles from './filter-header.module.scss';

type FilterHeaderProps = {
  className?: string;
  onClose: () => void;
  searchCmsContent: SearchCmsContent;
};

export const FilterHeader = ({ className, onClose, searchCmsContent }: FilterHeaderProps) => (
  <header className={classnames(styles['filter-header'], className)}>
    <h2 className={styles['filter-header__text']}>
      {searchCmsContent.searchPage?.FiltersLabel || 'Filters'}
    </h2>
    <Button
      Icon={CloseIcon}
      aria-label={searchCmsContent.generic.close}
      className={styles['filter-header__close-button']}
      onClick={onClose}
    />
  </header>
);
