import {
  type CommunicationPreferences,
  CommunicationPreferenceTopics,
  type CommunicationTopics,
} from '@module/mdrt-org/shared/utils/types';

export const mapCommunicationTopics = (
  communicationPreferences: CommunicationPreferences
): CommunicationTopics => {
  const keyToTopicMap: Record<keyof CommunicationTopics, CommunicationPreferenceTopics> = {
    businessAnnouncements: CommunicationPreferenceTopics.BusinessAnnouncements,
    contentNewsletter: CommunicationPreferenceTopics.ContentNewsletter,
    mdrtFoundation: CommunicationPreferenceTopics.MdrtFoundation,
    mdrtMeetings: CommunicationPreferenceTopics.MdrtMeetings,
    mdrtStore: CommunicationPreferenceTopics.MdrtStore,
    memberBenefits: CommunicationPreferenceTopics.MemberBenefits,
  };

  const isKey = (value: string): value is keyof typeof keyToTopicMap =>
    Object.keys(keyToTopicMap).includes(value);

  const keys = Object.keys(keyToTopicMap).filter(isKey);

  return Object.fromEntries(
    keys.map((currentValue: keyof CommunicationTopics) => [
      currentValue,
      communicationPreferences.mdrtPreferenceCenter?.emails?.find(
        (emailPermission) => emailPermission.topic === keyToTopicMap[currentValue]
      )?.optIn ?? false,
    ])
  ) as CommunicationTopics;
};
