'use client';

import { useUserContext } from '@module/mdrt-org/shared/components';
import { AccountSettingsFormSection, SocialPlatform } from '@module/mdrt-org/shared/utils/enums';
import { loginHandler } from '@module/mdrt-org/shared/utils/helpers';
import { AuthProviderName, type ParsedToken, parseJwtToken } from '@shared/misc';
import { CheckboxInput } from '@shared/ui-components';

import { type AccountSettingsLoginPasswordContent } from '../get-account-settings-login-password-content';

import styles from './social-logins.module.scss';

const platformUrls = {
  [SocialPlatform.Facebook]: 'facebook.com',
  [SocialPlatform.LinkedIn]: 'linkedin.com',
  [SocialPlatform.Google]: 'google.com',
  [SocialPlatform.Apple]: 'https://appleid.apple.com',
};

const isSocialLoginEnabled = (parsedToken: ParsedToken | undefined, platform: SocialPlatform) =>
  parsedToken?.alternativeSecurityIds?.some(
    (id) => id.identityProvider === platformUrls[platform]
  ) || false;

export const SocialLogins = ({
  cmsContent,
  locale,
}: {
  cmsContent: AccountSettingsLoginPasswordContent;
  locale: string;
}) => {
  const { accessToken, userId } = useUserContext();
  const parsedToken = accessToken ? parseJwtToken(accessToken) : undefined;

  const socialLoginsData = [
    { id: 'google', label: cmsContent.googleLabel, socialPlatform: SocialPlatform.Google },
    { id: 'apple', label: cmsContent.appleLabel, socialPlatform: SocialPlatform.Apple },
    { id: 'facebook', label: cmsContent.facebookLabel, socialPlatform: SocialPlatform.Facebook },
    { id: 'linkedin', label: cmsContent.linkedInLabel, socialPlatform: SocialPlatform.LinkedIn },
  ].map((platformData) => ({
    ...platformData,
    value: isSocialLoginEnabled(parsedToken, platformData.socialPlatform),
  }));

  const handleSwitchChange = (platform: SocialPlatform) => {
    const isChecked = isSocialLoginEnabled(parsedToken, platform);
    const authProvider = isChecked
      ? AuthProviderName.RemoveSocialSignIn
      : AuthProviderName.SocialSignIn;
    const authorizationParameters = {
      ui_locales: locale,
      userId,
      ...(isChecked && { provider: platformUrls[platform] }),
    };

    loginHandler({
      authorizationParameters,
      options: {
        redirectTo: `${cmsContent.pagePaths.accountSettingPagePath}#${AccountSettingsFormSection.LoginPassword}`,
      },
      provider: authProvider,
    });
  };

  return (
    <form className={styles['social-logins-form']}>
      <div>
        <h2 className={styles['social-logins-form__heading']}>{cmsContent.approvedSocialLogins}</h2>
        <fieldset className={styles['social-logins-form__fieldset']}>
          <legend className={styles['social-logins-form__legend']}>
            {cmsContent.browserPermissionInformation}
          </legend>
          {socialLoginsData.map((socialLoginElement) => (
            <CheckboxInput
              id={socialLoginElement.id}
              isChecked={socialLoginElement.value}
              isSwitch
              key={socialLoginElement.id}
              label={socialLoginElement.label}
              onChange={() => handleSwitchChange(socialLoginElement.socialPlatform)}
            />
          ))}
        </fieldset>
      </div>
    </form>
  );
};
