'use client';

import { type ChangeEvent, type Dispatch, type SetStateAction } from 'react';

import { type SyncEventsFilter } from '@module/mdrt-org/admin-dashboard/components/sync-events-table/get-sync-events-table-content';

import { SyncEvent } from '../../utils/data/types/sync-event';

import styles from './sync-events-table.module.scss';

export const SyncEventTableFilter = ({
  cmsContent,
  setEventType,
}: {
  cmsContent: SyncEventsFilter;
  setEventType: Dispatch<SetStateAction<SyncEvent | 'none' | undefined>>;
}) => {
  const options = [
    { label: cmsContent.syncEventsSelectAll, value: 'none' },
    {
      label: cmsContent.syncEventsSelectMembershipApplication,
      value: SyncEvent.MembershipApplicationSyncEvent,
    },
    {
      label: cmsContent.syncEventsSelectCustomerProfile,
      value: SyncEvent.CustomerProfileSyncEvent,
    },
    { label: cmsContent.syncEventsSelectPrepayment, value: SyncEvent.PrePaymentSyncEvent },
    { label: cmsContent.syncEventsSelectProduction, value: SyncEvent.ProductionSyncEvent },
  ];

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const eventType = event.target.value as 'none' | SyncEvent;
    setEventType(eventType);
  };

  return (
    <select className={styles['filter-select']} defaultValue="none" onChange={handleSelect}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
