import {
  type AdvancedFilterFormData,
  type FilterSection,
} from '../types/advanced-filter-form-data';

const getSectionItemCheckedCount = (formSectionState: { [key: string]: boolean }) =>
  Object.values(formSectionState).filter(Boolean).length;

export const getCheckedItemCount = (formState: AdvancedFilterFormData) => {
  const counts = {} as { [key in FilterSection]: number };

  for (const sectionKey of Object.keys(formState) as FilterSection[]) {
    counts[sectionKey] = getSectionItemCheckedCount(formState[sectionKey]);
  }

  return counts;
};
