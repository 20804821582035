import {
  type FilterOption,
  type FilterSection,
  type AdvancedFilterFormData,
  type AdvancedFilterOptions,
} from '../types/advanced-filter-form-data';

export const getDefaultValueObjectFromFilterOptions = (
  filterOptions: AdvancedFilterOptions,
  overrideValue?: boolean
) => {
  const defaultValueObject = {} as AdvancedFilterFormData;

  for (const [sectionKey, sectionFilterOptions] of Object.entries(filterOptions) as Array<
    [FilterSection, FilterOption[]]
  >) {
    defaultValueObject[sectionKey] = Object.fromEntries(
      sectionFilterOptions.map(({ name, defaultValue }) => [
        name,
        Boolean(overrideValue ?? defaultValue),
      ])
    );
  }

  return defaultValueObject;
};
