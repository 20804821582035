import {
  type CenterApplicationDefinition,
  type CommunicationPreferences,
  type CustomerProfile,
  MembershipType,
} from '@module/mdrt-org/shared/utils/types';

const member = 'Member';
const formerMember = 'Former Member';
const nonMember = 'Non-Member';

export type CommunicationPreferenceCenterType = keyof CommunicationPreferences;

export const CommunicationPreferenceCenterTypes: Record<string, CommunicationPreferenceCenterType> =
  {
    Center: 'mdrtCenterPreferenceCenter',
    MDRT: 'mdrtPreferenceCenter',
  };

export const getCurrentPreferenceCenter = (
  profile: CustomerProfile,
  currentCenterApplicationDefinition: CenterApplicationDefinition
) => {
  const currentYear = currentCenterApplicationDefinition.membershipYear;
  const gsYears = profile.gsMembershipYears;

  // rank is ordering membership level on 3 steps scale: member, former member, non-member
  let mdrtMemberTypeRank;
  let centerMemberTypeRank: number;

  switch (profile.memberType) {
    case member:
    case MembershipType.LifeMember:
    case MembershipType.QualifyingLifeMember:
    case MembershipType.QualifyingMember:
      mdrtMemberTypeRank = 2;
      break;
    case formerMember:
      mdrtMemberTypeRank = 1;
      break;
    case nonMember:
    default:
      mdrtMemberTypeRank = 0;
      break;
  }

  // increase the mdrt rank by 1 so equal levels of mdrt win over mdrt center
  mdrtMemberTypeRank += 1;

  if (gsYears?.includes(currentYear) || gsYears?.includes(currentYear + 1)) {
    // next year to include early buyers
    centerMemberTypeRank = 2;
  } else if (gsYears?.length > 0) {
    centerMemberTypeRank = 1;
  } else {
    centerMemberTypeRank = 0;
  }

  return centerMemberTypeRank >= mdrtMemberTypeRank
    ? CommunicationPreferenceCenterTypes.Center
    : CommunicationPreferenceCenterTypes.MDRT;
};
