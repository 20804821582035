type TranslatedLabels = {
  noLabel: string;
  yesLabel: string;
};
export const getYesNoLabel = (value: boolean, labels: TranslatedLabels) => {
  switch (value) {
    case true:
      return labels.yesLabel;
    case false:
      return labels.noLabel;
    default:
      return null;
  }
};
