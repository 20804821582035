'use client';

import { Button } from '@module/mdrt-org/shared/components/button';
import { loginHandler } from '@module/mdrt-org/shared/utils/helpers';
import { ButtonVariant } from '@shared/ui-components';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';

import styles from './no-results-message.module.scss';

type NoResultsMessageProps = {
  isAuthenticated: boolean;
  locale: string;
  searchPageCmsContent: SearchCmsContent['searchPage'];
};

export const NoResultsMessage = ({
  isAuthenticated,
  locale,
  searchPageCmsContent,
}: NoResultsMessageProps) => {
  return (
    <div className={styles.message}>
      <h2 className={styles['message__header']}>
        {searchPageCmsContent?.NoResultsFoundText || 'No results found'}
      </h2>
      <p className={styles['message__text']}>
        {searchPageCmsContent?.CheckYourSpellingText ||
          'Check your spelling, or try rephrasing your search term.'}
      </p>
      {!isAuthenticated && (
        <p className={styles['message__text']}>
          <span>{searchPageCmsContent?.IfNotMemberText || `If you're an MDRT member, please`}</span>
          <Button
            className={styles['message__button']}
            dataTestId="sign-in-button"
            isSmall
            onClick={() => loginHandler({ authorizationParameters: { ui_locales: locale } })}
            variant={ButtonVariant.Tertiary}
          >
            {searchPageCmsContent?.SignInLabel || 'sign in'}
          </Button>
        </p>
      )}
    </div>
  );
};
