export enum SyncEvent {
  CustomerProfileSyncEvent = 'customerProfileSyncEvent',
  MembershipApplicationSyncEvent = 'membershipApplicationSyncEvent',
  PrePaymentSyncEvent = 'prePaymentSyncEvent',
  ProductionSyncEvent = 'productionSyncEvent',
}

export enum SyncType {
  OnDemandSync = 'OnDemandSync',
  ProfileUpdate = 'ProfileUpdate',
  TimeTriggeredSync = 'TimeTriggeredSync',
  WebHookSync = 'WebHookSync',
}

export enum SyncOperation {
  Delete = 'Delete',
  Insert = 'Insert',
  Update = 'Update',
}

export type InboundSyncEventChange = {
  description: string | null;
  newValue: string;
  oldValue: string;
  propertyName: string;
};

export type InboundSyncEvent = {
  changes: InboundSyncEventChange[];
  documentType: string;
  syncOperation: SyncOperation;
  syncType: SyncType;
  syncedAtUtc: string;
};

export type InboundSyncEventsResponseData = {
  count: number;
  results: InboundSyncEvent[];
};
